import { useEffect, useRef, useState } from "react"
import { Station } from "../models/Station"
import { Cuve } from "../models/Cuve"
import StationsGetter from "../FormComponents/StationsGetter"
import CuvesGetter from "../FormComponents/CuvesGetter"
import { MAX_SELECTION_LIMIT } from "../utils/constants"
import { SelectButton } from "primereact/selectbutton"
import { MouvementCuve } from "../models/MouvementCuve"
import { HistoriqueCuve } from "../models/HistoriqueCuve"
import ControlList from "./ControlList"
import { Calendar } from "primereact/calendar"
import { getMouvementsCuveByCriteria, getMouvementsCuveByCriteriaPaged } from "../utils/apiCalls/cuves"
import { Toast } from "primereact/toast"
import { Button } from "primereact/button"
import './control.css'
import { Accordion, AccordionTab } from "primereact/accordion"
import { fillPaginationParams } from "../utils/PaginationParams"
import { UNSAFE_ErrorResponseImpl } from "react-router-dom"

function Control() {

    const [selectedStation, setSelectedStation] = useState<Station[]>([])
    const [selectedCuves, setSelectedCuves] = useState<Cuve[]>([])

    const [selectedTypeOperation, setSelectedTypeOperation] = useState(1);
    const [dateDebut, setDateDebut] = useState<Date>(new Date())
    const [dateFin, setDateFin] = useState<Date>(new Date())

    const [first, setFirst] = useState<number>(0)
    const [rows, setRows] = useState<number>(20)
    const [totalRecords, setTotalRecords] = useState<number>(0)

    const [operations, setOperations] = useState<MouvementCuve[] | HistoriqueCuve[]>([])

    const toast = useRef(null)

    const items = [
        { name: 'Mesures', value: 1 },
        { name: 'Entrées', value: 2 },
        { name: 'Sorties', value: 3 }
    ];

    const fetchOperations = () => {


        if (selectedTypeOperation === 1) {
            if (selectedCuves[0]) {
                getMouvementsCuveByCriteriaPaged(selectedCuves[0].id, dateDebut, dateFin,
                    fillPaginationParams(first, rows)
                )
                    .then(response => {

                        console.log(response.data)
                        setOperations(response.data.data)
                        setTotalRecords(response.data.meta.total)

                    })
                    .catch(error => {

                        (toast.current as unknown as any).show(
                            {
                                severity: 'error',
                                summary: 'Erreur',
                                detail: error.message
                            }
                        )


                    })
            }
        }
    }

    useEffect(() => {
        fetchOperations()
    }, [selectedTypeOperation, selectedCuves, selectedStation, dateDebut, dateFin])

    const handlePageChange = () => {
        fetchOperations();
    }

    return (
        <div style={containerStyle}>
            <Accordion>
                <AccordionTab header='Filtrer'>
                    <div style={formStyle}>
                        <StationsGetter disabled={false} maxSelection={1}
                            selectedStations={selectedStation}
                            setSelectedStations={setSelectedStation}
                        />
                        <CuvesGetter disabled={selectedStation.length === 0}
                            maxSelection={MAX_SELECTION_LIMIT} selectedCuves={selectedCuves}
                            setSelectedCuves={setSelectedCuves}
                            station={selectedStation[0]}
                        />

                        <div className="control-container">

                            <div className="select-button-container">

                                <SelectButton

                                    value={selectedTypeOperation}

                                    onChange={(e) => setSelectedTypeOperation(e.value)}

                                    optionLabel="name" options={items} />

                            </div>

                            <div className="calendar-container">

                                <span className="p-float-label">

                                    <Calendar value={dateDebut} locale="fr"

                                        onChange={(event) => setDateDebut(event.value ? event.value : new Date())} />

                                    <label>De: </label>

                                </span>

                                <span className="p-float-label">

                                    <Calendar value={dateFin} locale="fr"

                                        onChange={(event) => setDateFin(event.value ? event.value : new Date())} />

                                    <label>Fin: </label>

                                </span>

                            </div>


                            <div className="button-container">

                                <Button icon='pi pi-refresh' onClick={() => fetchOperations()} />

                            </div>

                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <div>
                <ControlList
                    first={first}
                    rows={rows}
                    setFirst={setFirst}
                    setRows={setRows}
                    fetchOperations={fetchOperations}
                    totalRecords={totalRecords}
                    operations={operations}
                    selectedTypeOperation={selectedTypeOperation}
                />
            </div>

            <Toast ref={toast} position="top-center" />
        </div>
    )

}

const containerStyle = {

    maxWidth: "80vw",
    margin: "2em auto",
    display: "flex",
    flexDirection: "column" as const,
    gap: "2em"

}

const controlStyle = {

    display: "flex",
    flexDirection: 'row' as const,
    gap: "2em",
    flexWrap: 'wrap' as const,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: "90vw",


}

const formStyle = {
    display: "flex",
    flexDirection: 'row' as const,
    gap: "2em",
    flexWrap: 'wrap' as const,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: "90vw",

}
export default Control