import axios, { AxiosInstance, AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import { Cuve } from "../../models/Cuve";
import { BASE_URL } from "../constants";
import { HistoriqueCuve } from "../../models/HistoriqueCuve";
import { BasePageResponse } from "../apiResponse/BasePagedResponse";
import { PaginationParams } from "../PaginationParams";

export function getCuveById(id: number): Promise<AxiosResponse<Cuve>> {
  return api.post("getCuveById", {
    cuve_id: id,
  });
}

export function createCuve(
  reference: string,
  capacite: number,
  station_id: number,
  article_id: number
) {
  return api.post("createCuve", {
    station_id: station_id,
    reference: reference,
    capacite: capacite,
    article_id: article_id,
  });
}

export function addMesureCuve(
  cuve_id: number,
  mesure: number,
  station_id: number,
  image: Blob | undefined
) {
  const accessToken = sessionStorage.getItem("authToken");

  const formData = new FormData();
  formData.append("cuve_id", cuve_id.toString());
  formData.append("mesure", mesure.toString());
  formData.append("station_id", station_id.toString());
  if (image) {
    formData.append("image", image, `image.jpeg`); // Set filename and contentType
  }

  return axios.post(BASE_URL + "addMesureCuve", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getMouvementsCuveByCriteria(
  cuve_id: number,
  dateDebut: Date,
  dateFin: Date
): Promise<AxiosResponse<HistoriqueCuve[]>> {
  return api.post("getMesuresCuveByCriteria", {
    dateDebut: dateDebut,
    dateFin: dateFin,
    cuve_id: cuve_id,
  });
}


export function getMouvementsCuveByCriteriaPaged(
  cuve_id: number,
  dateDebut: Date,
  dateFin: Date,
  paginationParams: PaginationParams
):Promise<BasePageResponse<HistoriqueCuve>>  {
  return api.post("getMesuresCuveByCriteriaPaged", {
    dateDebut: dateDebut,
    dateFin: dateFin,
    cuve_id: cuve_id,
    paginationParams: paginationParams
  });
}

export function getCuvesByStationID(
  station_id: number
): Promise<AxiosResponse<Cuve[]>> {
  return api.post("getCuvesByStationID", {
    station_id: station_id,
  });
}

export function getLatestMesureByCuveList(
  cuve_ids: number[]
): Promise<AxiosResponse<HistoriqueCuve[]>> {
  return api.post("getLatestMesureByCuveList", {
    cuve_ids: cuve_ids,
  });
}

export function updateCuve(
  cuve_id: number,
  reference: string,
  capacite: number,
  active: boolean,
  article_id: number
) {
  return api.post("updateCuve", {
    cuve_id: cuve_id,
    reference: reference,
    capacite: capacite,
    active: active,
    article_id: article_id,
  });
}

export function getFirstMesureDayCuvesList(cuve_ids: number[], date: Date) {
  return api.post("getFirstMesureDayCuvesList", {
    cuve_ids: cuve_ids,
    date: date,
  });
}
