import { act, useEffect, useRef, useState } from "react";
import { Station } from "../../models/Station";
import StationsGetter from "../../FormComponents/StationsGetter";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { createCuve, getCuveById, updateCuve } from "../../utils/apiCalls/cuves";
import { Toast } from "primereact/toast";
import ArticleGetter from "../../FormComponents/ArticleGetter";
import { Article } from "../../models/Article";
import { useNavigate, useParams } from "react-router-dom";
import { Cuve } from "../../models/Cuve";
import { Checkbox } from "primereact/checkbox";

function CreateCuveForm() {

    const [selectedStation, setSelectedStation] = useState<Station[]>([])
    const [capacite, setCapacite] = useState<number>(0)
    const [reference, setReference] = useState<string>('')
    const [selectedArticles, setSelectedArticles] = useState<Article[]>([])
    const [selectedCuve, setSelectedCuve] = useState<Cuve | undefined>()
    const [active, setActive] = useState<number>(0)
    const toast = useRef(null)
    const navigate = useNavigate();


    const { cuve_id } = useParams();


    const fetchCuve = () => {
        if (cuve_id) {
            getCuveById(parseInt(cuve_id))
                .then(response => {

                    setSelectedCuve(response.data)

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
        }
    }

    useEffect(() => {
        if (cuve_id) {
            fetchCuve();
        }
    }, [cuve_id])

    useEffect(() => {
        if (selectedCuve) {
            setReference(selectedCuve.reference)

            if (selectedCuve.article) {
                setSelectedArticles([selectedCuve.article])
            }

            setCapacite(selectedCuve.capacite)

            setActive(selectedCuve.active)
        }
    }, [selectedCuve])

    const [loading, setLoading] = useState<boolean>(false)

    const handleCreateButtonClick = () => {

        setLoading(true)

        if (selectedStation && selectedArticles[0]) {
            createCuve(reference, capacite, selectedStation[0].id, selectedArticles[0].id)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: "Cuve ajoutée"
                        }
                    )
                    setLoading(false)
                    resetForm();

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                    setLoading(true)

                })
        }
    }

    const handleUpdateButtonClick = () => {
        setLoading(true)


        if (selectedCuve && selectedArticles[0])
            updateCuve(selectedCuve.id, reference, capacite, active === 1, selectedArticles[0].id)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: "Cuve modifiée"
                        }
                    )

                    setLoading(false)

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                    setLoading(false)


                })

    }

    const resetForm = () => {
        setReference('')
        setCapacite(0)
    }

    return (<div style={containerStyle}>

        <h2>Nouvelle cuve</h2>

        <StationsGetter maxSelection={1}
            disabled={true}
            selectedStations={selectedStation}
            setSelectedStations={setSelectedStation} />


        <span className="p-float-label" style={{ width: "100%" }}>
            <InputText
                value={reference}
                onChange={(event) => setReference(event.target.value)}
                style={{ width: "100%" }} />
            <label>Référence</label>
        </span>

        <div style={{ width: "100%" }}>
            <ArticleGetter
                disabled={false}
                selectedArticles={selectedArticles}
                selectionLimit={1}
                setSelectedArticles={setSelectedArticles}
            />
        </div>


        <span className="p-float-label" style={{ width: "100%" }}>
            <InputNumber
                value={capacite}
                onChange={(event) => {
                    if (event.value !== null) {
                        setCapacite(event.value)
                    }
                }}
                style={{ width: "100%" }} locale="fr" />
            <label>Capacité maximale</label>
        </span>


        {
            selectedCuve &&
            <div style={checkboxStyle}>
                <Checkbox inputId="active"
                    name="active"
                    onChange={(event) => {
                        if (event && event.checked) {
                            if (event.checked) {
                                setActive(1)
                            }
                            else {
                                setActive(0)
                            }
                        }
                        else {
                            setActive(0)
                        }
                    }}
                    checked={active == 1 ? true : false} />
                <label htmlFor="ingredient1" className="ml-2">Actif</label>
            </div>
        }


        {!selectedCuve && <Button icon='pi pi-plus' label="Ajouter" onClick={() => { handleCreateButtonClick() }} />}
        {selectedCuve &&

            <div style={controlStyle}>

                <Button loading={loading} icon='pi pi-pencil' label="Modifier" onClick={() => { handleUpdateButtonClick() }} />
                <Button loading={loading} icon='pi pi-plus' severity="success" label="Nouvelle cuve" onClick={() => navigate('/operations/cuve')} />
            </div>}

        <Toast ref={toast} position="bottom-center" />





    </div>
    )
}


const containerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    jusifyContent: 'center',
    alignItems: "center",
    maxWidth: "80vw",
    margin: "auto",
    gap: "2em"

}

const controlStyle = {
    display: "flex",
    flexDirection: "column" as const,
    gap: "2em"
}


const checkboxStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row" as const,
    gap: "1em"
}

export default CreateCuveForm
