import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { searchUsersByUsername } from "../utils/apiCalls/users";
import { debounce } from 'lodash'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { User } from "../models/User";
import { Toast } from "primereact/toast";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { fillPaginationParams } from "../utils/PaginationParams";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
function SearchUser() {

    const [username, setUsername] = useState<string>('')
    const [users, setUsers] = useState<User[]>([])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [totalRecords, setTotalRecods] = useState<number>(0)


    const navigate = useNavigate();


    const fetchUsers = () => {
        searchUsersByUsername(username, fillPaginationParams(first, rows))
            .then(response => {

                setTotalRecods(response.data.meta.total)
                setUsers(response.data.data)
            })
            .catch(error => {
                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message,
                    }
                )
            })
    }



    const handlePageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first)
        setRows(event.rows)
    }


    useEffect(() => {
        fetchUsers();

    }, [first, rows])

    const debouncedFetchUsers = debounce(fetchUsers, 300);
    const toast = useRef(null)

    useEffect(() => {

        debouncedFetchUsers();

        return (() => {
            debouncedFetchUsers.cancel()
        })

    }, [username])


    return (
        <div style={containerStyle}>


            <div style={headerStyle} >
                <Button icon='pi pi-user-plus'
                    label="Ajouter un utilisateur"
                    onClick={() => navigate('/users')}
                />
                <span className='p-float-label'>
                    <InputText
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <label>Nom d'utilisateur</label>
                </span>
            </div>

            <DataTable value={users} scrollHeight="50vh" tableStyle={{ width: '80vw',  }}>
                <Column header="Nom d'utilisateur" field="username" />
                <Column header="Modifier"  body={(rowData) => {
                    return <Button 
                    onClick={() => navigate(`/users/${rowData.id}`)}
                    className="mr-2"
                     icon='pi pi-pencil' />
                }}  />
            </DataTable>
            <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[20, 50]} onPageChange={handlePageChange} />

            <Toast ref={toast} />
        </div>
    )

}

const containerStyle = {
    display: 'flex',
    justifyContent: "center",
    alignItems:'center',
    margin: "2em",
    flexDirection: "column" as const,
    gap: "2em"
}

const headerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5em",
}

export default SearchUser;