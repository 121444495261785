import './App.css';
import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primeicons/primeicons.css';
import Building from './Maintainance/Building';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginContainer from './LoginScreen/LoginContainer';
import Dashboard from './Dashboard/Dashboard';
import WithAuth from './configAuth/WithAuth';
import MenuBar from './menubar/Menubar';
import SearchUser from './Users/SearchUser';
import CreateStationForm from './stations/CreateStationForm';
import ListeStations from './stations/ListeStations';
import SingleStationScreen from './stations/SingleStationScreen';
import NewUserForm from './Users/NewUserForm';
import SingleUserScreen from './Users/SingleUserScreen';
import StationsOperations from './stations/StationsOperations';
import { addLocale } from 'primereact/api';
import CreateCuveForm from './Operations/Cuves/EditCuveForm';
import HistoriqueCuveComponent from './Operations/Cuves/HistoriqueCuveComponent';
import MesureCuve from './Operations/Cuves/MesureCuve';
import Dayboard from './Operations/DayBoard/Dayboard';
import MouvementEntree from './Operations/Cuves/MouvementCuve';
import MouvementCuve from './Operations/Cuves/MouvementCuve';
import ClientsList from './Clients/ClientsList';
import EditClient from './Clients/EditClient';
import ArticlesList from './Articles/ArticlesList';
import EditArticle from './Articles/EditArticle';
import ListCuves from './Operations/Cuves/ListCuves';
import TableauShift from './ResumeJour/TableauShift';
import MouvementsClient from './Clients/MouvementsClients';
import Control from './Control/Control';



function App() {


  addLocale('fr', {
    startsWith: "Commence par",
    contains: "Contient",
    notContains: "Ne contient pas",
    endsWith: "Se termine par",
    equals: "Égal à",
    notEquals: "Différent de",
    noFilter: "Aucun filtre",
    filter: "Filtre",
    lt: "Inférieur à",
    lte: "Inférieur ou égal à",
    gt: "Supérieur à",
    gte: "Supérieur ou égal à",
    dateIs: "La date est",
    dateIsNot: "La date n'est pas",
    dateBefore: "Avant le",
    dateAfter: "Après le",
    custom: "Personnalisé",
    clear: "Effacer",
    apply: "Appliquer",
    matchAll: "Correspond à tous",
    matchAny: "Au moins un Correspond",
    addRule: "Ajouter une règle",
    removeRule: "Retirer une règle",
    accept: "Oui",
    reject: "Non",
    choose: "Choisir",
    upload: "Envoyer",
    cancel: "Annuler",
    pending: "En attente",
    fileSizeTypes: [
      "o",
      "Ko",
      "Mo",
      "Go",
      "To",
      "Po",
      "Eo",
      "Zo",
      "Yo"
    ],
    dayNames: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi"
    ],
    dayNamesShort: [
      "Dim",
      "Lun",
      "Mar",
      "Mer",
      "Jeu",
      "Ven",
      "Sam"
    ],
    dayNamesMin: [
      "Di",
      "Lu",
      "Mar",
      "Mer",
      "Je",
      "Ve",
      "Sa"
    ],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Jui",
      "Août",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ],
    chooseYear: "Choisir une année",
    chooseMonth: "Choisir un mois",
    chooseDate: "Choisir une date",
    prevDecade: "Décennie précédente",
    nextDecade: "Décennie suivante",
    prevYear: "Année précédente",
    nextYear: "Année suivante",
    prevMonth: "Mois précédent",
    nextMonth: "Mois suivant",
    prevHour: "Heure précédente",
    nextHour: "Heure suivante",
    prevMinute: "Minute précédente",
    nextMinute: "Minute suivante",
    prevSecond: "Seconde précédente",
    nextSecond: "Seconde suivante",
    am: "am",
    pm: "pm",
    today: "Aujourd'hui",
    weekHeader: "Sem",
    firstDayOfWeek: 1,
    dateFormat: "dd/mm/yy",
    weak: "Faible",
    medium: "Moyen",
    strong: "Fort",
    passwordPrompt: "Saisissez un mot de passe",
    emptyFilterMessage: "Aucun résultat trouvé",
    emptyMessage: "Aucune option disponible",
    aria: {
      trueLabel: "Vrai",
      falseLabel: "Faux",
      nullLabel: "Aucune sélection",
      star: "1 étoile",
      stars: "{star} étoiles",
      selectAll: "Tous éléments sélectionnés",
      unselectAll: "Tous éléments désélectionnés",
      close: "Fermer",
      previous: "Précédent",
      next: "Suivant",
      navigation: "Navigation",
      scrollTop: "Défiler tout en haut",
      moveTop: "Déplacer tout en haut",
      moveUp: "Déplacer vers le haut",
      moveDown: "Déplacer vers le bas",
      moveBottom: "Déplacer tout en bas",
      moveToTarget: "Déplacer vers la cible",
      moveToSource: "Déplacer vers la source",
      moveAllToTarget: "Tout déplacer vers la cible",
      moveAllToSource: "Tout déplacer vers la source",
      pageLabel: "Page {page}",
      firstPageLabel: "Première Page",
      lastPageLabel: "Dernière Page",
      nextPageLabel: "Page Suivante",
      rowsPerPageLabel: "Lignes par page",
      previousPageLabel: "Page précédente",
      jumpToPageDropdownLabel: "Aller à la page",
      jumpToPageInputLabel: "Aller à la page",
      selectRow: "Ligne sélectionnée",
      unselectRow: "Ligne désélectionnée",
      expandRow: "Ligne dépliée",
      collapseRow: "Ligne repliée",
      showFilterMenu: "Montre le menu des filtres",
      hideFilterMenu: "Masque le menu des filtres",
      filterOperator: "Opérateur de filtrage",
      filterConstraint: "Contrainte de filtrage",
      editRow: "Édite une ligne",
      saveEdit: "Sauvegarde l'édition",
      cancelEdit: "Annule l'édition",
      listView: "Vue en liste",
      gridView: "Vue en grille",
      slide: "Diapositive",
      slideNumber: "{slideNumber}",
      zoomImage: "Zoomer l'image",
      zoomIn: "Zoomer",
      zoomOut: "Dézoomer",
      rotateRight: "Tourner vers la droite",
      rotateLeft: "Tourner vers la gauche"

    }
  })



  return (
    <div>
      <BrowserRouter >
        <Routes >
          <Route
            path="*"
            element={
              <div>
                <MenuBar />
                <Routes>
                  <Route path="/" element={<Building />} />
                  <Route path="/login" element={<LoginContainer />} />

                  <Route path="/dashboard" element={WithAuth(Dashboard, 1)} />

                  <Route path="/users/list" element={WithAuth(SearchUser, 2)} />
                  <Route path="/users" element={WithAuth(NewUserForm, 3)} />
                  <Route path='/users/:id' element={WithAuth(SingleUserScreen, 10)} />

                  <Route path="/stations" element={WithAuth(CreateStationForm, 4)} />
                  <Route path="/stations/list" element={WithAuth(ListeStations, 5)} />
                  <Route path="/stations/edit/:id" element={WithAuth(SingleStationScreen, 6)} />
                  {/* <Route path="/stations/operations" element={WithAuth(StationsOperations, 11)}/> */}

                  <Route path="/stations/operations/cuves/mesure" element={WithAuth(MesureCuve, 13)} />
                  <Route path="/operations/cuve" element={WithAuth(CreateCuveForm, 14)} />
                  <Route path="/operations/cuve/:cuve_id" element={WithAuth(CreateCuveForm, 14)} />

                  <Route path="/operations/cuves/:station_id" element={WithAuth(ListCuves, 22)} />
                  


                  <Route path="/stations/operations/cuves/:cuve_id" element={WithAuth(HistoriqueCuveComponent, 15)} />
                  <Route path="/stations/operations" element={WithAuth(Dayboard, 11)} />

                  {//mouvements de cuve 
                  }
                  <Route path='/stations/operations/:station_id/:type_mouvement' element={WithAuth(MouvementCuve, 16)} />

                  <Route path='/clients' element={WithAuth(ClientsList, 18)} />

                  <Route path='/clients/edit' element={WithAuth(EditClient, 19)} />

                  <Route path='/clients/edit/:param_numero_compte' element={WithAuth(EditClient, 19)} />
                  <Route path='/clients/mvt/:param_numero_compte' element= {WithAuth(MouvementsClient, 24)}/>

                  <Route path='/articles' element={WithAuth(ArticlesList, 20)} />

                  <Route path='/articles/edit' element={WithAuth(EditArticle, 21)} />

                  
                  <Route path='/articles/edit/:param_article_id' element={WithAuth(EditArticle, 21)} />


                  <Route path='/articles/edit/:param_article_id' element={WithAuth(EditArticle, 21)} />

                  <Route path='/stations/tableauShift/:station_id' element={WithAuth(TableauShift, 23)} />


                  <Route path='/control' element={WithAuth(Control, 25)} />



                </Routes>

              </div>

            }
          />
        </Routes>

      </BrowserRouter>
    </div>
  );
}



export default App;
