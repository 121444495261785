import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { stat } from "fs";
import { Cuve } from "../../models/Cuve";
import { Station } from "../../models/Station";
import { addMesureCuve, getCuveById } from "../../utils/apiCalls/cuves";
import PhotoCamera from "../../modules/PhotoCamera";
import StationsGetter from "../../FormComponents/StationsGetter";
import CuvesGetter from "../../FormComponents/CuvesGetter";




function MesureCuve() {

    const [selectedStations, setSelectedStations] = useState<Station[]>([])
    const [selectedCuves, setSelectedCuves] = useState<Cuve[]>([])
    const [mesure, setMesure] = useState<number>(0)
    const [imgBlob, setImgBlob] = useState<Blob>()
    const toast = useRef(null)


    const handleSaveClick = () => {

            if (selectedCuves[0] && selectedStations[0])
                addMesureCuve(selectedCuves[0].id, mesure, selectedStations[0].id, imgBlob)
                    .then(response => {
    
                        (toast.current as unknown as any).show(
                            {
                                severity: 'success',
                                summary: 'ok',
                                detail: 'Mesure enregistree'
                            }
                        )
    
                        setMesure(0)
                        setImgBlob(undefined)
    
                    })
                    .catch(error => {
    
                        (toast.current as unknown as any).show(
                            {
                                severity: 'error',
                                summary: 'Erreur',
                                detail: error.message
                            }
                        )
    
                    }
                    )
                }
    
   

    return (
        <div style={containerStyle}>

            <div style={addMeasureContainerStyle}>
                <h4>Ajouter une mesure</h4>

                <span className="p-float-label" style={{ width: "100%" }}>
                    <Calendar style={{ width: "100%" }} value={new Date()} disabled locale="fr" />
                    <label>Date</label>
                </span>

                <StationsGetter disabled maxSelection={1} selectedStations={selectedStations} setSelectedStations={setSelectedStations} />
                <CuvesGetter disabled={false} selectedCuves={selectedCuves} setSelectedCuves={setSelectedCuves} maxSelection={1}
                    station={selectedStations[0]} />

                <span className="p-float-label" style={{ width: "100%" }}>
                    <InputNumber style={{ width: "100%" }} value={mesure}
                        onChange={(event) => setMesure(event.value ? event.value : 0)} />
                    <label>Mesure</label>
                </span>

                <PhotoCamera imgBlob={imgBlob} setImgBlob={setImgBlob} />

                <Button label="Enregistrer" onClick={() => handleSaveClick()} />
            </div>

            <Toast ref={toast} position="bottom-center" />

        </div>

    )


}

const containerStyle = {
    maxWidth: "80vw",
    margin: "2em auto",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "2em"
}

const addMeasureContainerStyle = {
    display: 'flex',
    flexDirection: "column" as const,
    gap: "2em",
    border: "1px solid lightgray",
    borderRadius: "0.3em",
    padding: "2em"

}
export default MesureCuve;

