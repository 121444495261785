import { useEffect, useRef, useState } from "react"
import { Station } from "../models/Station"
import { Cuve } from "../models/Cuve"
import { getCuvesByStationID } from "../utils/apiCalls/cuves"
import { Toast } from "primereact/toast"
import { MultiSelect } from "primereact/multiselect"

function CuvesGetter(
    {
        station,
        selectedCuves,
        setSelectedCuves,
        maxSelection,
        disabled
    }
        :
        {
            station: Station,
            selectedCuves: Cuve[],
            maxSelection: number,
            disabled: boolean,
            setSelectedCuves: any
        }
) {

    const [cuveOptions, setCuveOptions] = useState<Cuve[]>([])
    const toast = useRef(null)

    useEffect(() => {
        if (station)
            fetchCuves();
    }, [station])

    const fetchCuves = () => {
        if (station) {
            getCuvesByStationID(station.id)
                .then(response => {

                    setCuveOptions(response.data)



                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
        }
    }

    useEffect(() => {
        if (cuveOptions.length > 0) {
            setSelectedCuves([cuveOptions[0]])
        }
    }, cuveOptions)


    return <div style={{ width: "100%" }}>

        <span className="p-float-label" style={{ width: "100%" }}>
            <MultiSelect
                style={{ width: "100%" }}
                selectionLimit={maxSelection}
                disabled={disabled}
                options={cuveOptions}
                optionLabel="reference"
                value={selectedCuves}
                onChange={(event) => setSelectedCuves(event.value)}
            />
            <label>Cuve</label>
        </span>
        <Toast position="top-center" ref={toast} />
    </div>

}

export default CuvesGetter