import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import { Card } from 'primereact/card';
import { checkAccess } from '../utils/apiCalls/auth';

const WithAuth = (WrappedComponent: any, componentid: number) => {

  const AuthenticatedComponent = () => {
    const [hasPermission, setHasPermission] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    


    React.useEffect(() => {

      const checkPermission = async () => {


        try {

          const response = await checkAccess(componentid)


          setHasPermission(response.data)

        } catch (error) {

          setHasPermission(false)

        } finally {

          setLoading(false);

        }

      };

      checkPermission();

    }, [componentid]);


    if (loading) {
      return <div>Loading...</div>;
    }


    if (!hasPermission) {
      return <div>You do not have permission to access this component.</div>;

    }


    return < WrappedComponent />
  }

  return <AuthenticatedComponent />;
};


export default WithAuth;