
import { Button } from "primereact/button";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";


function PhotoCamera({
  imgBlob,
  setImgBlob
}
  :
  {
    imgBlob: Blob | undefined,
    setImgBlob: any
  }) {

  const [img, setImg] = useState(null);
  const webcamRef = useRef(null);

  useEffect(() =>{
    if(!imgBlob)
    {
      setImg(null)
    }
  }, [imgBlob])




  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "environment",

  };

  const capture = useCallback(() => {
    if (webcamRef.current) {

      const imageSrc = (webcamRef.current as unknown as any).getScreenshot();
      setImgBlob(new Blob([imageSrc], { type: 'image/jpeg' }));



      setImg(imageSrc);

    }
  }, [webcamRef]);

  return (
    <div className="Container">
      {img === null ? (
        <>
          <Webcam
            audio={false}
            mirrored={false}

            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            style={{
              width: "25em",
              
            }}
          />
          <div style={btnContainerStyle}>
            <button style={captureBtnStyle} onClick={capture}>
              <div style={buttonInnerDiv}></div>
            </button>
          </div>
        </>
      ) : (
        <>
          <img src={img} alt="screenshot" style={{
            width: "25em",
            
          }} />
          <div style={btnContainerStyle}>

            <Button severity="danger" onClick={() => setImg(null)} icon='pi pi-times' />
          </div>
        </>
      )}
    </div>
  );
}
export default PhotoCamera

const captureBtnStyle = {

  backgroundColor: "#8a8a8a",

  border: "none",
  padding: '0.1em',
  borderRadius: "50%",

  height: "50px",

  width: "50px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"

};


const buttonInnerDiv = {


  backgroundColor: "lightgray",
  borderRadius: "50%",
  height: "80%",
  width: "80%"


};


const btnContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}