import { notEqual } from "assert";
import ImageDisplayer from "../../ImageDisplayer/ImageDisplayer"
import { HistoriqueCuve } from "../../models/HistoriqueCuve"
import './HistoriqueCuves.css'; // Import the CSS file
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Cuve } from "../../models/Cuve";
import { getCuveById } from "../../utils/apiCalls/cuves";
import { Toast } from "primereact/toast";


function HistoriqueCuveTile(
    {
        mouvementCuve,
        displayImage
    }
        :
        {
            mouvementCuve: HistoriqueCuve,
            displayImage: boolean
        }
) {


    const [showImage, setShowImage] = useState<boolean>(false)
    const [cuve, setCuve]=useState<Cuve>()
    const toast = useRef(null)


    const getCuve =  () =>{
        getCuveById(mouvementCuve.cuve_id)
        .then(response =>{
           setCuve(response.data)
        })
        .catch(error =>{

            (toast.current as unknown as any).show(
                {
                    severity: 'error',
                    summary: 'Erreur',
                    detail: error.message
                }
            )

        })
    }

    useEffect(() =>{


        if(mouvementCuve)
        {
            getCuve();
        }

    }, [mouvementCuve])




    return (


        <div style={mesureTileStyle} >
            <div style={numberWrapper}>
                <div style={detailsRowStyle}>
                <i className="pi pi-box"></i>
                <span > Cuve: {cuve?.reference}</span>
                </div>
                <div style={detailsRowStyle}>
                    <i className="pi pi-calendar"></i>
                    <span > Date: {new Date(mouvementCuve.created_at).toLocaleDateString('fr')}</span>
                </div>

                <div style={detailsRowStyle}>
                    <i className="pi pi-gauge"></i>
                    <span > Jauge: {(mouvementCuve.mesure).toLocaleString('fr')}</span>
                </div>

                <div style={detailsRowStyle}>
                    <i className="pi pi-warehouse"></i>
                    <span > Stock actuel: {cuve?.stock.toLocaleString('fr')}</span>
                </div>



            </div>

            {
                displayImage && <div className="imageDisplayer">
                    {showImage && <ImageDisplayer
                        fileName={`${mouvementCuve.image_uuid}.jpeg`} />}
                    {!showImage && <Button style={imgDisplayBtnStyle}
                        label="Afficher l'image"
                        severity="secondary"
                        icon='pi pi-camera'
                        size="small"
                        onClick={() => {
                            setShowImage(true)
                        }} />
                    }
                    {showImage && <Button style={imgDisplayBtnStyle}
                        label="Masquer l'image"
                        severity="secondary"
                        icon='pi pi-times'
                        size="small"
                        onClick={() => {
                            setShowImage(false)
                        }} />}
                </div>
            }

            <Toast ref={toast} position="top-center" />
        </div>

    )

}


const mesureTileStyle = {
    border: "1px solid #dec173",
    borderRadius: "0.3em",
    backgroundColor: "#faf4e6",
    width: "100%",
    margin:"0.2em 0",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column" as const

}

const imgDisplayBtnStyle =
{
    margin:"0.5em 0"
}


const numberWrapper = {
    display: "flex",
    flexDirection: "row" as const,
    gap:"0.5em",
    flexWrap: "wrap" as const, // allow items to wrap to the next line on smaller screens
    maxWidth: "100vw", // limit the width to the viewport width
    padding: "1em", // add some padding to make it more readable
}


const detailsRowStyle = {
    display: "flex",
    flexDirection: "row" as const,
    gap: '1em',
    margin:"0 0.5em",
    alignItems: "flex-start",
    justifyContent: "flex-start",

}

export default HistoriqueCuveTile