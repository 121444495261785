import axios, { AxiosInstance, AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import Client from "../../models/Client";
import { PaginationParams } from "../PaginationParams";
import { BasePageResponse } from "../apiResponse/BasePagedResponse";

export function addClient(
  numero_compte: string,
  intitule: string,
  enSommeil: boolean
): Promise<AxiosResponse<Client>> {
  return api.post("addClient", {
    numero_compte: numero_compte,
    intitule: intitule,
    enSommeil: enSommeil,
  });
}

export function getClientByNumeroCompte(
  numero_compte: string
): Promise<AxiosResponse<Client>> {
  return api.post("getClientByNumeroCompte", {
    numero_compte: numero_compte,
  });
}

export function updateClient(client: Client): Promise<AxiosResponse<Client>> {
  return api.post("editClient", {
    client: client,
  });
}

export function getClientByCriteria(
  intitule: string,
  sommeil: boolean,
  paginationParams: PaginationParams
): Promise<BasePageResponse<Client>> {
  return api.post("getClientByCriteria", {
    intitule: intitule,
    sommeil: sommeil,
    paginationParams: paginationParams,
  });
}
