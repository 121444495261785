import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import loginImg from '../assets/loginImg.webp'
import { Image } from 'primereact/image';
import { useRef, useState } from 'react';
import { login } from '../utils/apiCalls/auth';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

function LoginContainer() {


    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate();



    const handleLogin = () => {
        setLoading(true)
        login(username, password)
            .then(response => {
                if (response.data.token) {
                    sessionStorage.setItem('authToken', response.data.token)

                    setLoading(false)
                    navigate('/dashboard');
                }

            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)
            }
            )


    }

    const toast = useRef(null)



    return (
        <>
            <div style={containerStyle}>
                <h1 style={{ margin: 0, padding: 0 }}>Petropulse  v 1.0</h1>
                <h2 style={{ margin: 0, padding: 0 }}>Connexion a votre compte</h2>

                <div style={formStyle}>
                    <Image src={loginImg} width='250' />
                    <span className="p-float-label">
                        <InputText
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                            style={{ width: "100%" }} />
                        <label>Nom d'utilisateur</label>
                    </span>

                    <span className="p-float-label">
                        <InputText
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            style={{ width: "100%" }}
                            type='password' />
                        <label>Mot de passe</label>
                    </span>

                    <Button
                        loading={loading}
                        label='Se connecter'
                        icon='pi pi-user'
                        onClick={() => handleLogin()} />

                    <Toast ref={toast} position='bottom-center' />
                </div>




            </div>
        </>
    )

}
const containerStyle = {

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as const,
    gap: "1em",
    height: "90vh"

}

const formStyle = {

    border: "1px solid lightgray",
    padding: "4em",
    display: "flex",
    flexDirection: "column" as const,
    gap: '1.5em'

}

export default LoginContainer;