import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Client from "../models/Client";
import { getClientByNumeroCompte } from "../utils/apiCalls/clients";
import { Toast } from "primereact/toast";
import ClientGetter from "../FormComponents/ClientGetter";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { facturerMouvementList, getMouvementsByClient, reglerMouvementsClientList } from "../utils/apiCalls/MouvementsClient";
import { fillPaginationParams } from "../utils/PaginationParams";
import { MouvementsClient } from "../models/MouvementsClients";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator, PaginatorChangeEvent, PaginatorPageChangeEvent } from "primereact/paginator";

function MouvementsClientList() {

    const { param_numero_compte } = useParams();
    const navigate = useNavigate();
    const [selectedClient, setSelectedClient] = useState<Client[]>([])
    const [dateDebut, setDateDebut] = useState<Date>(new Date())
    const [dateFin, setDateFin] = useState<Date>(new Date())
    const [mouvementsClients, setMouvementsClient] = useState<MouvementsClient[]>([])
    const [first, setFirst] = useState<number>(0)
    const [rows, setRows] = useState<number>(20)
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedMouvements, setSelectedMouvements] = useState<MouvementsClient[]>([])
    const [rowClick, setRowClick] = useState<boolean>(true)



    useEffect(() => {
        fetchMouvementsClient();
    }, [first, rows])


    const toast = useRef(null)

    useEffect(() => {
        if (selectedClient.length === 1)
            navigate(`/clients/mvt/${selectedClient[0].numero_compte}`)
    }, [selectedClient])

    const fetchClient = () => {
        if (param_numero_compte) {
            getClientByNumeroCompte(param_numero_compte)
                .then(response => {
                    setSelectedClient([response.data])
                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                })
        }

    }

    const fetchMouvementsClient = () => {
        setLoading(true)
        if (selectedClient.length > 0)
            getMouvementsByClient(selectedClient[0].numero_compte, dateDebut,
                dateFin, fillPaginationParams(first, rows))
                .then(response => {

                    setMouvementsClient(response.data.data)
                    setTotalRecords(response.data.meta.total)
                    setLoading(false)


                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                    setLoading(false)
                })
    }





    useEffect(() => {
        fetchClient();
    }, [param_numero_compte])

    const handlePageChange = (event: PaginatorPageChangeEvent) => {


        setFirst(event.first)
        setRows(event.rows)

    }

    const facturerSelection = () => {
        setLoading(true)
        facturerMouvementList(selectedMouvements.map(item => item.id))
            .then(response => {
                (toast.current as unknown as any).show(
                    {
                        severity: 'success',
                        summary: 'Ok',
                        detail: "Opération réussie"
                    }
                )
                setLoading(false)


                fetchMouvementsClient();
            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)

            })
    }


    const reglerSelection = () => {

        setLoading(true)
        reglerMouvementsClientList(selectedMouvements.map(item => item.id))
            .then(response => {
                (toast.current as unknown as any).show(
                    {
                        severity: 'success',
                        summary: 'Ok',
                        detail: "Opération réussie"
                    }
                )

                setLoading(false)

                fetchMouvementsClient();
            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)

            })


    }
    return (<div style={containerStyle}>

        <ClientGetter disabled={false}
            selectedClients={selectedClient}
            setSelectedClient={setSelectedClient}
            selectionLimit={1}
        />
        <div style={dateSelectionWrapperStyle}>
            <span className="p-float-label">
                <Calendar value={dateDebut} locale="fr"
                    onChange={(event) => setDateDebut(event.value ? event.value : new Date())} />
                <label>De: </label>
            </span>
            <span className="p-float-label">
                <Calendar value={dateFin} locale="fr"
                    onChange={(event) => setDateFin(event.value ? event.value : new Date())} />
                <label>Fin: </label>
            </span>
            <Button icon='pi pi-refresh' onClick={(event) => {
                fetchMouvementsClient();
            }} />

            <Button loading={loading} icon='pi pi-file' label="Facturer" severity="info" onClick={() => { facturerSelection() }} />
            <Button loading={loading} icon='pi pi-money-bill' label="Règler" severity="help"
                onClick={() => reglerSelection()}
            />
        </div>

        <DataTable value={mouvementsClients}
            loading={loading}
            selectionMode={rowClick ? null : 'checkbox'}
            onSelectionChange={(e: any) => setSelectedMouvements(e.value)} dataKey="id"
            selection={selectedMouvements}
        >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
            <Column header='Date' field="created_at" body={(rowData) => {
                return new Date(rowData.created_at).toLocaleDateString('fr');
            }
            } />
            <Column header='Article' field='article.intitule' />
            <Column header='Quantité' field='quantite'
                body={(rowData) => {
                    return rowData.quantite.toLocaleString('fr')
                }} />

            <Column header='Montant' field='quantite'
                body={(rowData) => {
                    return rowData.montant.toLocaleString('fr')
                }} />


            <Column header="Facturé" field="facture"
                body={(rowData) => {
                    return <i className={
                        rowData.facture ? 'pi pi-check' : 'pi pi-times'
                    }></i>

                }}
            />

            <Column header="Règlé" field="reglement"
                body={(rowData) => {
                    return <i className={
                        rowData.reglement ? 'pi pi-check' : 'pi pi-times'
                    }></i>

                }}
            />


        </DataTable>

        <Paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[20, 50]} onPageChange={handlePageChange}
        />
        <Toast ref={toast} position="top-center" />
    </div>)

}


const containerStyle = {
    margin: "2em",

}

const dateSelectionWrapperStyle =
{
    display: 'flex',
    flexWrap: 'wrap' as const,
    alignItems: 'center',
    gap: '2em',
    margin: "2em 0"
}

export default MouvementsClientList