import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Station } from "../../models/Station";
import { getCuvesByStationID, getLatestMesureByCuveList } from "../../utils/apiCalls/cuves";
import { Toast } from "primereact/toast";
import { Cuve } from "../../models/Cuve";
import { HistoriqueCuve } from "../../models/HistoriqueCuve";
import HistoriqueCuveListContainer from "../Cuves/HistoriqueCuveListContainer";
import { isAdonisJSDateToday } from "../../utils/utils";
import { Message } from "primereact/message";
import { TYPES_MOUVEMENT_STOCK } from "../../utils/constants";

function DayOperationsPanel(
    {

        selectedStation,
        allMeasuresTaken,
        setAllMeasuresTaken
    }
        :
        {
            selectedStation: Station | undefined,
            allMeasuresTaken: boolean,
            setAllMeasuresTaken: any

        }
) {

    const [mesuresInitiales, setMesuresInitiales] = useState<HistoriqueCuve[]>([]); // verifie si la mesure initiale en debut de journee est faite sinon les autres actions ne seront pas disponibles
    const [cuves, setCuves] = useState<Cuve[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const navigate = useNavigate();
    const toast = useRef(null)



    const getCuves = () => {
        if (selectedStation)
            getCuvesByStationID(selectedStation.id)
                .then(response => {
                    setCuves(response.data)
                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
    }

    const getMesuresInitiales = () => {
        setLoading(true)
        const cuvesIds = cuves.map(item => {
            return item.id
        })

        if (cuvesIds.length > 0) {

            getLatestMesureByCuveList(cuvesIds)
                .then(response => {


                    setMesuresInitiales(response.data)
                    setLoading(false)

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }

                    )
                    setLoading(false)

                })

        }
    }
    useEffect(() => {
        getCuves();

    }, [])

    useEffect(() => {

        getMesuresInitiales();

    }, [cuves])



    useEffect(() => {
        if (mesuresInitiales) {

            let badMeausureFound = false  //PRENDS TRUE SI UNE MESURE CORRESPOND A UNE ANCIENNE DATE

            cuves.forEach(cuve => {

                const mesure = mesuresInitiales.find(mesure => {
                    return mesure.cuve_id === cuve.id
                })

                if (!mesure) {
                    badMeausureFound = true
                }
                else if (!isAdonisJSDateToday(mesure?.created_at)) {
                    badMeausureFound = true

                }
                else {
                    badMeausureFound = false
                }


                setAllMeasuresTaken(!badMeausureFound)

            })
        }
    }, [mesuresInitiales])



    return (
        <div style={panelStyle}>

            <HistoriqueCuveListContainer historiquesCuve={mesuresInitiales} />



            {
                !allMeasuresTaken &&
                <Message
                    severity="error"
                    text='Enregistrez une mesure initiale pour chaque cuve avant de pouvoir effectuer les mouvements' />
            }


            <Button onClick={() => navigate('/stations/operations/cuves/mesure')} style={btnStyle} icon='pi pi-gauge' label="Mesure des cuves" />



            {
                allMeasuresTaken && selectedStation &&
                <>
                    <Button
                        style={btnStyle}
                        icon='pi pi-arrow-up-right'
                        label="Mouvements d'entrée"
                        severity="success"
                        onClick={() => {
                            navigate(`/stations/operations/${selectedStation.id}/${TYPES_MOUVEMENT_STOCK.ENTREE}`)
                        }}
                    />
                    <Button
                        onClick={() => {
                            navigate(`/stations/operations/${selectedStation.id}/${TYPES_MOUVEMENT_STOCK.SORTIE}`)
                        }}
                        style={btnStyle}
                        icon='pi pi-arrow-down-right'
                        label="Mouvements de sortie"
                        severity="warning" />
                </>
            }


            <Toast ref={toast} position="top-center" />

        </div>
    )
}

const panelStyle = {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
    margin: "2em 0"
}

const btnStyle = {
    width: "40vw"
}

export default DayOperationsPanel;