import { useParams } from "react-router-dom";
import { getRoleForUser, getUserById, updateUser } from "../utils/apiCalls/users";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { User } from "../models/User";
import { Station } from "../models/Station";
import RoleGetter from "../FormComponents/RoleGetter";
import { Role } from "../models/Role";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import StationsGetter from "../FormComponents/StationsGetter";
import { stat } from "fs";
import { MAX_SELECTION_LIMIT } from "../utils/constants";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

function SingleUserScreen() {

    const { id } = useParams();

    const [user, setUser] = useState<User | undefined>()
    const [username, setUsername] = useState<string>('')
    const [stations, setStations] = useState<Station[]>([])
    const [role, setRole] = useState<Role | undefined>()

    const toast = useRef(null)


    const getUser = () => {

        if (id)
            getUserById(parseInt(id))
                .then(response => {

                    if (response.data) {
                        setUser(response.data)

                    }

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
    }


    const getUserRole = () => {
        if (user) {
            getRoleForUser(user.id)
                .then(response => {

                    setRole(response.data)

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
        }
    }

    const handleEditClick = () => {

        if (id && role) {
            updateUser(parseInt(id), username, role.id, stations.map(item => item.id))
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'ok',
                            detail: "Utilisateur modifié"
                        }
                    )

                    getUser();

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )


                })
        }

    }


    useEffect(() => {
        getUser();
    }, [id])


    useEffect(() => {


        if (user) {

            if (user.role) {
                setRole(user.role)
            }
            else {
                getUserRole();
            }

            setUsername(user.username)
            setStations(user.stations)


        }

    }, [user])

    return (
        <div style={containerStyle}  >

            <span className="p-float-label" style={{ width: "100%" }}>
                <InputText

                    style={{ width: "100%" }}
                    placeholder="Nom d'utilisateur" value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    invalid={username.length == 0} />
                <label>Nom d'utilisateur</label>
            </span>
            <RoleGetter selectedRole={role}
                setSelectedRole={setRole} />

            <StationsGetter selectedStations={stations}
                disabled={false}
                maxSelection={MAX_SELECTION_LIMIT}
                setSelectedStations={setStations} />

            <Button icon='pi pi-pencil' label="Modifier" onClick={() => handleEditClick()} />

            <Toast position="bottom-center" ref={toast} />
        </div>
    )

}

const containerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    width: "80vw",
    margin: "3em auto",
    gap: "2.5em"
}

export default SingleUserScreen