import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { HistoriqueCuve } from "../models/HistoriqueCuve";
import { MouvementCuve } from "../models/MouvementCuve";
import HistoriqueCuveTile from "../Operations/Cuves/HistoriqueCuveTile";
import { useEffect } from "react";


// { name: 'Mesures', value: 1 },
// { name: 'Entrées', value: 2 },
// { name: 'Sorties', value: 3 }
function ControlList(
    {
        operations,
        selectedTypeOperation,
        first,
        rows,
        totalRecords,
        setFirst, 
        setRows,
        fetchOperations
    }
        :
        {
            operations: MouvementCuve[] | HistoriqueCuve[],
            selectedTypeOperation: number,
            first: number,
            rows: number,
            totalRecords: number,
            setFirst: any,
            setRows: any,
            fetchOperations:any
        }
) {


    const handlePageChange = (event: PaginatorPageChangeEvent) =>{
        setFirst(event.first)
        setRows(event.rows)
    }
    
    useEffect(() =>{

        fetchOperations()

    }, [first, rows])

    if (selectedTypeOperation === 1)
        return (<div style={containerStyle}>

            <div style={operationsListStyle}>
                {operations.map((item, index) => {
                    return <HistoriqueCuveTile displayImage={true}
                        mouvementCuve={item as HistoriqueCuve}
                        key={index} />
                })}
            </div>
            <Paginator first={first}
                rows={rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5, 20, 50]} 
                onPageChange={handlePageChange} />
        </div>)

    else
        return (<>ENTREES / SORTIES</>)

}

const containerStyle = {

    display: "flex",
    gap: "1em",
    flexWrap: "wrap" as const,
    flexDirection: "column" as const



}

const operationsListStyle = {
    maxHeight: "80vh",
    overflowY: 'scroll' as const,
    gap: "1em",
    display: "flex",
    flexDirection: "column" as const
}

export default ControlList;