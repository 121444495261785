import { Dropdown } from "primereact/dropdown"
import { Role } from "../models/Role"
import { useEffect, useRef, useState } from "react"
import { getRoles } from "../utils/apiCalls/auth"
import { Toast } from "primereact/toast"

function RoleGetter(
    {
        selectedRole,
        setSelectedRole
    }
        :
        {
            selectedRole: Role | undefined,
            setSelectedRole: any
        }
) {


    const [roleOptions, setRoleOptions] = useState<Role[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const toast = useRef(null)

    const fetchRoles = () => {
        setLoading(true)

        getRoles()
            .then(response => {

                setRoleOptions(response.data)
                setLoading(false)


            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )

                setLoading(false)

            })


    }

    useEffect(() => {
        fetchRoles();
    }, [])


    return (<div style={{ width: "100%" }}>
        <span className="p-float-label" style={{ width: "100%" }}>
            <Dropdown
                style={{ width: "100%" }}
                value={selectedRole}
                options={roleOptions}
                onChange={(event) => setSelectedRole(event.value)}
                optionLabel="role" />
            <label>Rôle</label>
        </span>
        <Toast position="bottom-center" ref={toast} />
    </div>)


}
export default RoleGetter