import { Image } from "primereact/image"

export function ImageDisplayer( 
    {
        fileName
    }
    :
    {
            fileName: string
    }
)
{


    if(process.env.REACT_APP_ENVIRONEMENT === 'DEV')
    {

        
        return (

            <Image src={`/devImages/${fileName}`} width="100%"/>

        )
    }
    else
    {
        return (
            <Image src={`${process.env.REACT_APP_IMAGES_URL}/${fileName}`} width="100%"/>
        )
    }

}

export default ImageDisplayer