import { useEffect, useRef, useState } from "react"
import { Station } from "../models/Station"
import { MultiSelect } from "primereact/multiselect"
import { debounce } from "lodash"
import { searchStations } from "../utils/apiCalls/stations"
import { Toast } from "primereact/toast"
import { setTokenSourceMapRange } from "typescript"

function StationsGetter(
    {
        selectedStations,
        setSelectedStations,
        maxSelection,
        disabled

    }
        :
        {
            selectedStations: Station[],
            setSelectedStations: any,
            maxSelection: number,
            disabled:boolean
        }
) {


    const toast = useRef(null)
    const [loading, setLoading] = useState<boolean>(false)

    const [stationFilter, setStationFilter] = useState<string>('')
    const [stationOptions, setStationOptions] = useState<Station[]>([])


    const fetchStations = () => {

        setLoading(true)

        searchStations(stationFilter, {
            limit: 40,
            page: 1
        })
            .then(response => {
                setStationOptions(response.data.data)
                setLoading(false)

                if (maxSelection === 1) {
                    setSelectedStations([response.data.data[0]])
                }
            })
            .catch(error => {
                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)
            })


    }

    const debouncedFetchStations = debounce(fetchStations, 300)

    useEffect(() => {
        debouncedFetchStations();
        return (() => {
            debouncedFetchStations.cancel();
        })
    }, [stationFilter])

    return (
        <div style={{ width: "100%" }}>
            <span className="p-float-label" style={{ width: "100%" }}>
                <MultiSelect
                    style={{ width: "100%" }}
                    loading={loading}
                    selectionLimit={maxSelection}
                    optionLabel="name"
                    options={stationOptions}
                    value={selectedStations}
                    onChange={(event) => setSelectedStations(event.value)}
                    filter
                    disabled={disabled}
                    onFilter={(event) => setStationFilter(event.filter)}
                />
                <label>Stations</label>
            </span>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )

}

export default StationsGetter