import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Article } from "../models/Article";
import { addClient } from "../utils/apiCalls/clients";
import { addArticle, getArticleByID, updateArticle } from "../utils/apiCalls/articles";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import Price from "../models/Price";

function EditArticle() {
    const { param_article_id } = useParams();


    const [selectedArticle, setSelectedArticle] = useState<Article>()
    const [loading, setLoading] = useState<boolean>(false)
    const [intitule, setIntitule] = useState<string>('')
    const [prix, setPrix] = useState<number>(0)

    const navigate = useNavigate()


    const toast = useRef(null)

    const handleAddArticleClick = () => {
        addArticle(intitule, prix)
            .then(response => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'success',
                        summary: 'Ok',
                        detail: 'Client ajouté'
                    }
                )

                navigate(`/articles/edit/${response.data.id}`)


            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
            })

    }

    const handleUpdateArticleClick = () => {
        if (selectedArticle)
            updateArticle(
                {
                    id: selectedArticle.id,
                    intitule: intitule,
                    createdAt: selectedArticle.createdAt,
                    owner_id: selectedArticle.owner_id,
                    updatedAt: selectedArticle.updatedAt,
                    price: {
                        valeur: prix

                    }
                }
            )
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: 'Article modifié'
                        }
                    )

                    setSelectedArticle(response.data)

                })
                .catch(error => {


                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
    }


    useEffect(() => {

        if (param_article_id) {
            getArticleByID(parseInt(param_article_id))
                .then(response => {
                    setSelectedArticle(response.data)
                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )


                })
        }
    }
        , [param_article_id])

    useEffect(() => {


        if (selectedArticle) {
            setIntitule(selectedArticle.intitule)
            if (selectedArticle.price instanceof Array) {
                setPrix(selectedArticle.price[0]?.valeur || 0);
            } else {
                setPrix(selectedArticle.price.valeur || 0);
            }
        }

    }, [selectedArticle])

    return (
        <div style={containerStyle}>

            <div style={addClientFormStyle}>

                <h3>
                    {
                        selectedArticle ? "Modifier l'article"
                            :
                            "Ajouter un nouvel article"
                    }

                </h3>


                <span className="p-float-label">
                    <InputText placeholder="Intitulé" style={{ width: "100%" }} value={intitule}
                        onChange={(event) => setIntitule(event.target.value)} />
                    <label>Intitulé</label>

                </span>

                <span className="p-float-label">
                    <InputNumber placeholder="Prix"
                        min={0}
                        locale="fr"
                        style={{ width: "100%" }} value={prix}
                        onChange={(event) => setPrix(event.value ? event.value : 0)}

                    />

                    <label>Prix</label>
                </span>




                {!selectedArticle &&
                    <Button
                        label="Ajouter article"
                        icon='pi pi-plus'
                        loading={loading}
                        onClick={() => handleAddArticleClick()}
                    />}

                {
                    selectedArticle &&
                    <>
                        <Button
                            label="Modifier l'article"
                            icon='pi pi-pencil'
                            loading={loading}
                            onClick={() => handleUpdateArticleClick()}
                        />

                        <Button
                            label="Ajouter un nouvel article"
                            icon='pi pi-plus'
                            severity="success"
                            onClick={() => { navigate('/articles/edit') }}
                        />
                    </>
                }

            </div>

            <Toast ref={toast} position="top-center" />

        </div>
    )


}

const containerStyle = {

    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
    margin: "2em",

}

const addClientFormStyle = {
    border: "1px solid lightgray",
    borderRadius: '0.3em',
    width: "fit-content",
    padding: '2em',
    display: "flex",
    flexDirection: "column" as const,
    gap: "2em"

}

const checkboxStyle = {
    display: "flex",
    flexDirection: "row" as const,
    gap: "1em"
}

export default EditArticle