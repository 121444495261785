import { Calendar } from "primereact/calendar"
import { useParams } from "react-router-dom"
import StationsGetter from "../../FormComponents/StationsGetter"
import { useEffect, useRef, useState } from "react"
import { Station } from "../../models/Station"
import { getStationById } from "../../utils/apiCalls/stations"
import { Toast } from "primereact/toast"
import { TYPES_MOUVEMENT_STOCK } from "../../utils/constants"
import { Cuve } from "../../models/Cuve"
import CuvesGetter from "../../FormComponents/CuvesGetter"
import { InputNumber } from "primereact/inputnumber"
import PhotoCamera from "../../modules/PhotoCamera"
import { Button } from "primereact/button"
import { addMouvementStock } from "../../utils/apiCalls/MouvementStock"
import ClientGetter from "../../FormComponents/ClientGetter"
import Client from "../../models/Client"
import { Article } from "../../models/Article"
import ArticleGetter from "../../FormComponents/ArticleGetter"

function MouvementCuve(

) {

    const { station_id, type_mouvement } = useParams()
    const [selectedStations, setSelectedStations] = useState<Station[]>([])
    const [selectedCuves, setSelectedCuves] = useState<Cuve[]>([])
    const [quantite, setQuantite] = useState<number>(0)
    const [imgBlob, setImgBlob] = useState<Blob>()
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedClients, setSelectedClients] = useState<Client[]>([])
    const [selectedArticles, setSelectedArticles] = useState<Article[]>([])


    const toast = useRef(null)


    useEffect(() => {
        if (selectedCuves[0]) {
            const article = selectedCuves[0].article;

            if (article) {
                setSelectedArticles([article])
            }
        }
    }, [selectedCuves])

    const handleSaveClick = () => {

        setLoading(true)

        if (type_mouvement && selectedCuves[0] && selectedArticles[0])
            addMouvementStock(parseInt(type_mouvement), quantite, selectedCuves[0].id,
                selectedArticles[0].id,
                imgBlob)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: 'Mouvement ajouté'
                        }
                    )

                    setLoading(false)
                    resetForm()

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                    setLoading(false)

                })

    }

    const resetForm = () => {

        setQuantite(0)
        setImgBlob(undefined)

    }



    const getStation = () => {
        if (station_id)
            getStationById(parseInt(station_id))
                .then(response => {

                    setSelectedStations([response.data])

                })
                .catch(error => {


                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )


                })
    }

    useEffect(() => {
        getStation();
    }, [station_id])


    return (
        <div style={containerStyle}>

            <div style={addMeasureContainerStyle}>

                {
                    type_mouvement && parseInt(type_mouvement?.toString()) === TYPES_MOUVEMENT_STOCK.ENTREE ?
                        <h4>Entrée de stock</h4>
                        :
                        <h4>Sortie de stock</h4>
                }

                <span className="p-float-label" style={{ width: "100%" }}>
                    <Calendar style={{ width: "100%" }} value={new Date()} disabled locale="fr" />
                    <label>Date</label>
                </span>

                <StationsGetter disabled maxSelection={1} selectedStations={selectedStations} setSelectedStations={setSelectedStations} />

                <CuvesGetter disabled={false} selectedCuves={selectedCuves} setSelectedCuves={setSelectedCuves} maxSelection={1}
                    station={selectedStations[0]} />

                <ArticleGetter selectedArticles={selectedArticles} disabled={true}
                    selectionLimit={1}
                    setSelectedArticles={setSelectedArticles} />


                {
                    (type_mouvement && parseInt(type_mouvement)) === TYPES_MOUVEMENT_STOCK.SORTIE &&
                    <ClientGetter disabled={false}
                        selectedClients={selectedClients}
                        selectionLimit={1}
                        setSelectedClient={setSelectedClients}
                    />
                }

                <span className="p-float-label" style={{ width: "100%" }}>
                    <InputNumber style={{ width: "100%" }} value={quantite}
                        onChange={(event) => setQuantite(event.value ? event.value : 0)} />
                    <label>Quantité</label>
                </span>


                <PhotoCamera imgBlob={imgBlob} setImgBlob={setImgBlob} />






                <Button label="Enregistrer" onClick={() => handleSaveClick()} />



            </div>
            <Toast ref={toast} position="top-center" />

        </div>

    )


}

const containerStyle = {
    maxWidth: "80vw",
    margin: "2em auto",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "2em"
}

const addMeasureContainerStyle = {
    display: 'flex',
    flexDirection: "column" as const,
    gap: "2em",
    border: "1px solid lightgray",
    borderRadius: "0.3em",
    padding: "2em"

}
export default MouvementCuve;

