import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Client from "../models/Client";
import { InputText } from "primereact/inputtext";
import { displayPartsToString, setEmitFlags } from "typescript";
import { Button } from "primereact/button";
import { addClient, getClientByNumeroCompte, updateClient } from "../utils/apiCalls/clients";
import { Toast } from "primereact/toast";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";

function EditClient() {

    const { param_numero_compte } = useParams();


    const [selectedClient, setSelectedClient] = useState<Client>()
    const [loading, setLoading] = useState<boolean>(false)
    const [enSommeil, setEnSommeil] = useState<boolean>(false)
    const [numero_compte, setNumero_compte] = useState<string>('')
    const [intitule, setIntitule] = useState<string>('')

    const navigate = useNavigate()


    const toast = useRef(null)

    const handleAddClientClick = () => {
        addClient(numero_compte, intitule, enSommeil)
            .then(response => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'success',
                        summary: 'Ok',
                        detail: 'Client ajouté'
                    }
                )

                navigate(`/clients/edit/${response.data.numero_compte}`)

            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )

            })
    }

    const handleUpdateClientClick = () => {
        if (selectedClient)
            updateClient(
                {
                    id: selectedClient.id,
                    active: !enSommeil,
                    intitule: intitule,
                    createdAt: selectedClient.createdAt,
                    numero_compte: selectedClient.numero_compte,
                    owner_id: selectedClient.owner_id,
                    updatedAt: selectedClient.updatedAt
                }
            )
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: 'Client modifié'
                        }
                    )

                    setSelectedClient(response.data)

                })
                .catch(error => {


                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
    }


    useEffect(() => {

        if (param_numero_compte) {
            getClientByNumeroCompte(param_numero_compte)
                .then(response => {
                    setSelectedClient(response.data)
                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )


                })
        }
    }, [param_numero_compte])

    useEffect(() => {


        if (selectedClient) {
            setNumero_compte(selectedClient.numero_compte)
            setIntitule(selectedClient.intitule)
            setEnSommeil(!selectedClient.active)
        }

    }, [selectedClient])

    return (
        <div style={containerStyle}>

            <div style={addClientFormStyle}>

                <h3>
                    {
                        selectedClient ? "Modifier le client"
                            :
                            "Ajouter un nouveau compte"
                    }

                </h3>
                <span className="p-float-label">
                    <InputText placeholder="Numéro de compte" style={{ width: "100%" }} value={numero_compte}
                        onChange={(event) => setNumero_compte(event.target.value)} />
                    <label>Numéro de compte</label>
                </span>

                <span className="p-float-label">
                    <InputText placeholder="Intitulé" style={{ width: "100%" }} value={intitule}
                        onChange={(event) => setIntitule(event.target.value)} />
                    <label>Intitulé</label>
                </span>

                <div className="flex align-items-center" style={checkboxStyle}>
                    <Checkbox inputId="enSommeil"
                        name="EnSommeil"
                        value={'test'}
                        onChange={(event) => {
                            if (event && event.checked)
                                setEnSommeil(event.checked)
                            else setEnSommeil(false)
                        }}
                        checked={enSommeil || false} />
                    <label htmlFor="ingredient1" className="ml-2">En sommeil</label>
                </div>


                {!selectedClient &&
                    <Button
                        label="Ajouter client"
                        icon='pi pi-plus'
                        loading={loading}
                        onClick={() => handleAddClientClick()}
                    />}

                {
                    selectedClient &&
                    <>
                        <Button
                            label="Modifier client"
                            icon='pi pi-pencil'
                            loading={loading}
                            onClick={() => handleUpdateClientClick()}
                        />

                        <Button
                            label="Ajouter un nouveau compte"
                            icon='pi pi-plus'
                            severity="success"
                            onClick={() => { navigate('/clients/edit') }}
                        />
                    </>
                }

            </div>

            <Toast ref={toast} position="top-center" />

        </div>
    )


}

const containerStyle = {

    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
    margin: "2em",

}

const addClientFormStyle = {
    border: "1px solid lightgray",
    borderRadius: '0.3em',
    width: "fit-content",
    padding: '2em',
    display: "flex",
    flexDirection: "column" as const,
    gap: "2em"

}

const checkboxStyle = {
    display: "flex",
    flexDirection: "row" as const,
    gap: "1em"
}
export default EditClient;