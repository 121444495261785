import { useEffect, useRef, useState } from "react";
import Client from "../models/Client";
import { MultiSelect } from "primereact/multiselect";
import { getClientByCriteria } from "../utils/apiCalls/clients";
import { Toast } from "primereact/toast";
import { debounce } from "lodash";

function ClientGetter(
    {
        selectedClients,
        setSelectedClient,
        selectionLimit,
        disabled
    }
        :

        {
            selectedClients: Client[],
            setSelectedClient: any,
            selectionLimit: number,
            disabled: boolean
        }
) {

    const [clientOptions, setClientOptions] = useState<Client[]>([])
    const [clientFilter, setClientFilter] = useState<string>('');
    const toast = useRef(null)

    const fetchClients = () => {

        getClientByCriteria(clientFilter,false, {
            limit: 30, page: 1
        })
            .then(response => {
                setClientOptions(response.data.data)
            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )

            })

    }

    const debouncedFetchClients = debounce(fetchClients, 300)

    useEffect(() => {

        debouncedFetchClients();

        return (() => {
            debouncedFetchClients.cancel()
        })


    }, [clientFilter])




    return (
        <div>

            <span className="p-float-label">
                <MultiSelect value={selectedClients}
                    filter
                    style={{ width: "100%" }}
                    onChange={(event) => setSelectedClient(event.value)}
                    onFilter={(event) => setClientFilter(event.filter)}
                    options={clientOptions}
                    optionLabel="intitule"
                    disabled={disabled}
                    selectionLimit={selectionLimit}
                />
                <label>Client</label>


            </span>

            <Toast ref={toast} position="top-center" />
        </div>
    )

}


export default ClientGetter