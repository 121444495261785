export interface PaginationParams
{
    page:number
    limit:number
}


export function fillPaginationParams(first:number, rows: number): PaginationParams
{
    // sets the pagination params as the backend expects them
    //by converting prime react 's row and first to adonis js page and limit values

    return {
       page: Math.floor(first / rows) + 1,
       limit: rows
    }
}