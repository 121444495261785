import { Accordion, AccordionTab } from "primereact/accordion";
import { HistoriqueCuve } from "../../models/HistoriqueCuve";
import HistoriqueCuveTile from "./HistoriqueCuveTile";

function HistoriqueCuveListContainer(
    {
        historiquesCuve
    }
        :
        {
            historiquesCuve: HistoriqueCuve[]
        }
) {

    return (

        <Accordion activeIndex={0} >
            <AccordionTab header="Dernières mesures enregistrées"
>
                {
                    (historiquesCuve && historiquesCuve.length > 0) ?
                        historiquesCuve.map((item, index) => {
                            return <HistoriqueCuveTile displayImage={false} mouvementCuve={item} key={index} />
                        })
                        :
                        <div>Aucune mesure enregistrée</div>
                }
            </AccordionTab>
        </Accordion>
    )

}

export default HistoriqueCuveListContainer