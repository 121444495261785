import { useEffect, useRef, useState } from "react";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import { Station } from "../models/Station";
import { getStationById, getTableauShift } from "../utils/apiCalls/stations";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Cuve } from "../models/Cuve";
import { getCuvesByStationID, getFirstMesureDayCuvesList } from "../utils/apiCalls/cuves";
import { HistoriqueCuve } from "../models/HistoriqueCuve";
import { TableauShiftData } from "../models/TableauShiftData";
import { getTTFB } from "web-vitals";
import { blob } from "stream/consumers";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function TableauShift() {

    const { station_id } = useParams()
    const [station, setStation] = useState<Station>()
    const [date, setDate] = useState<Date>(new Date())
    const [cuves, setCuves] = useState<Cuve[]>([])
    const [mesuresOuverture, setMesuresOuverture] = useState<HistoriqueCuve[]>([])
    const [shiftData, setShiftData] = useState<TableauShiftData[]>([])
    const toast = useRef(null)

    const navigate = useNavigate();

    useEffect(() => {

        fetchStation();

    }, [station_id])


    const fetchStation = () => {

        if (station_id) {
            getStationById(parseInt(station_id))
                .then(response => {

                    setStation(response.data)

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
        }
    }

    const fetchCuves = () => {
        if (station)
            getCuvesByStationID(station.id)
                .then(response => {

                    setCuves(response.data)

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                })
    }
    useEffect(() => {
        fetchCuves();
    }, [station])

    const fetchShiftData = () => {
        if (station_id)
            getTableauShift(parseInt(station_id), date)
                .then(response => {

                    setShiftData(response.data)
                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
    }


    useEffect(() => {


        fetchShiftData();
    }, [station])


    return (
        <div style={containerStyle}>
            <h1>Tableau de shift station {station?.name}</h1>

            <div style={datePickerStyle}>

                <span className="p-float-label">
                    <Calendar locale="fr" value={date} onChange={(e) => setDate(e.value ? e.value : new Date())} />
                    <label>Date</label>
                </span>
                <Button icon='pi pi-refresh' onClick={(event) => {
                    fetchShiftData();
                }} />
                <Button label="Opérations" icon='pi pi-arrow-right-arrow-left'
                    onClick={(event) => {
                        navigate('/stations/operations')
                    }}
                />


            </div>

            <div style={tableStyle}>
                {/* <table>
                    <tr >
                        <th style={taleColumnStyle}>Cuve</th>
                        <th style={taleColumnStyle}>Ouverture</th>
                        <th style={taleColumnStyle}>Reception</th>
                        <th style={taleColumnStyle}>Vente</th>
                        <th style={taleColumnStyle}>R/C</th>
                        <th style={taleColumnStyle}>Fermeture</th>
                        <th style={taleColumnStyle}>Ecart</th>
                    </tr>
                    {shiftData.map((shiftData: TableauShiftData, index: number) => {

                        return (
                            <tr key={index}>
                                <td style={taleColumnStyle}>{shiftData.cuve.reference}</td>
                                <td style={taleColumnStyle}>
                                    {shiftData.ouverture}
                                </td>
                                <td style={taleColumnStyle}>{shiftData.reception}</td>
                                <td style={taleColumnStyle}>{shiftData.vente}</td>
                                <td style={taleColumnStyle}>{0}</td>
                                <td style={taleColumnStyle}>{shiftData.fermeture}</td>
                                <td style={taleColumnStyle}>{shiftData.ecart}</td>

                            </tr>

                        );

                    })}

                </table> */}

                <DataTable value={shiftData} scrollable size="small" style={{ minWidth: '10rem'}}>


                    <Column header='Cuve' field='cuve.reference' />
                    <Column header='Ouverture' field="ouverture" />
                    <Column header='Reception' field="reception" />
                    <Column header='Vente' field="vente" />
                    <Column header='R/C' field="0" />
                    <Column header='Fermeture' field="fermeture" />
                    <Column header='Ecart' field="ecart" />



                </DataTable>
            </div>

            <Toast ref={toast} position="top-center" />
        </div>
    )

}


const containerStyle = {
    maxWidth: "95vw",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as const
}
const tableStyle = {

    margin: "2em auto",
    overflowX: "auto" as const,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

}

const datePickerStyle = {
    display: "flex",
    flexDirection: "row" as const,
    gap: "1em"
}

const taleColumnStyle = {
    border: "1px solid black",
    borderCollaps: "collapse",
    padding: '1em'
}

export default TableauShift;