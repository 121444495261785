import { AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import { User } from "../../models/User";
import { PaginationParams } from "../PaginationParams";
import { BasePageResponse } from "../apiResponse/BasePagedResponse";
import { Role } from "../../models/Role";

export function addUser(username: string, password:string, roleId:number)
{

    return api.post('createUser',
        {
            username: username,
            password: password,
            role_id: roleId
        }
    )
    
}


export function searchUsersByUsername(username: string, paginationParams: PaginationParams): Promise<BasePageResponse<User>>
{
    return api.post('searchUsersByUsername', {
        username:username,
        paginationParams: paginationParams
    })
}


export function getUserById(user_id:number): Promise<AxiosResponse<User>>
{
    return api.post('getUserById', {
        user_id: user_id
    })
}


export function getRoleForUser(user_id: number): Promise<AxiosResponse<Role>>
{
    return api.post('getRoleForUser', {
        user_id: user_id
    })
}

export function updateUser(user_id: number, username: string, roleID: number, stationsID: number[])
{
    return api.post("updateUser", {
        id: user_id,
        username: username, 
        stationIDs: stationsID,
        roleID: roleID
    })
}