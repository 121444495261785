import { useEffect, useRef, useState } from "react"
import { Article } from "../models/Article"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { getArticlesByCriteria } from "../utils/apiCalls/articles"
import { fillPaginationParams } from "../utils/PaginationParams"
import { Toast } from "primereact/toast"
import { debounce } from "lodash"

function ArticleGetter(

    {
        selectedArticles,
        setSelectedArticles,
        selectionLimit,
        disabled
    }
        :
        {
            selectedArticles: Article[]
            setSelectedArticles: any
            selectionLimit: number
            disabled: boolean
        }

) {

    const [articleFilter, setArticleFilter] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [articleOptions, setArticleOptions] = useState<Article[]>([])
    const toast = useRef(null)

    const fetchArticles = () => {
        if (disabled) {
            return;
        }

        setLoading(true)
        getArticlesByCriteria(articleFilter, fillPaginationParams(0, 50))
            .then(response => {

                setArticleOptions(response.data.data)

                console.log(response)
                setLoading(false)

            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'success',
                        summary: 'Ok',
                        detail: 'Client ajouté'
                    }
                )
                setLoading(false)


            })

    }

    const debouncedFetchArticles = debounce(fetchArticles, 300)

    useEffect(() => {

        debouncedFetchArticles();

        return (() => {
            debouncedFetchArticles.cancel();
        })

    }, [articleFilter])



    return (
        <div style={{ width: "100%" }}>

            <span className="p-float-label" style={{ width: "100%" }}
            >
                <MultiSelect
                    style={{ width: "100%" }}
                    options={articleOptions}
                    optionLabel="intitule"
                    loading={isLoading}
                    filter
                    onFilter={(event) => setArticleFilter(event.filter)}
                    onChange={(event) => setSelectedArticles(event.value)}
                    value={selectedArticles}
                    selectionLimit={selectionLimit}
                    disabled={disabled}
                    loadingIcon={isLoading}

                />
                <label>Article</label>

            </span>


            <Toast ref={toast} position="top-center" />
        </div>
    )

}


export default ArticleGetter