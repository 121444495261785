import { AxiosInstance, AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import { PaginationParams } from "../PaginationParams";
import { BasePageResponse } from "../apiResponse/BasePagedResponse";
import { Station } from "../../models/Station";
import { TableauShiftData } from "../../models/TableauShiftData";
import { stat } from "fs";
import { STATUS_CODES } from "http";

export function addStation(name: string, address: string, user_ids: number[])
{

    return api.post('createStation',
        {
            name: name,
            address:address, 
            user_ids: user_ids
        }
    )
    
}


export function searchStations(name: string, paginationParams: PaginationParams): Promise<BasePageResponse<Station>>
{
    return api.post('getStationsByCriteria',
        {
            name: name,
            paginationParams: paginationParams
        }
    )
}

export function getStationById(id:number)
{
    return api.post('getStationById', {
        id:id
    })
}

export function updateStation(id:number, name: string, address: string, userIds: number[])
{
    return api.post('updateStation', {
        id: id,
        name:name,
        address: address,
        userIds: userIds
    })
}


export function getStationsForUser() : Promise<AxiosResponse<Station[]>>
{
    return api.post('getStationsForUser')
}


export function getTableauShift(station_id: number, date: Date): Promise<AxiosResponse<TableauShiftData[]>>
{
    return api.post('getTableauShift', {

        station_id: station_id,
        date: date

    })
}