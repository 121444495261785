import { Image } from 'primereact/image';
import under_construction from '../assets/under_construction.gif'
import { useNavigate } from 'react-router-dom';
import {Button} from 'primereact/button'
function Building() {

    const navigate = useNavigate();


    return (
        <div style={containerStyle}>
            <Image src={under_construction}
           
            alt="Image" width="100%" />


            <Button  label='Accéder à la démo'
            onClick={() => {navigate('/login')}}
            />
            
        </div>
    )

}

const containerStyle = {
    backgroundColor:"#4b52db",
    height:"100vh",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column" as const,
    alignItems:"center"
}

export default Building