import axios, { AxiosResponse } from "axios";
import { apiFunctionsFactory } from "../utils";
import { BASE_URL } from "../constants";
import api from "../../configAxios/AxiosHttpWrapper";
import { Role } from "../../models/Role";



export function login(username:string, password:string)
{
    const options = apiFunctionsFactory('post', BASE_URL+'login', 
        {
            username:username,
            password:password
        }
    )

    return axios.request(options)
}



export function checkAuth(): Promise<AxiosResponse>
{
    return api.get('')
}


export function getRoles(): Promise<AxiosResponse<Role[]>>
{
    return api.get('/getRoles')
}


export function checkAccess(component_id: number, description: string = '')
{
        return api.post('/checkAccess', {
            component_id: component_id,
            description: description
        })

}