import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { addStation } from "../utils/apiCalls/stations";
import { Toast } from "primereact/toast";
import UsersGetter from "../FormComponents/UsersGetter";
import { User } from "../models/User";
import { MAX_SELECTION_LIMIT } from "../utils/constants";

function CreateStationForm() {

    const [name, setName] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedUsers, setSelectedUsers] = useState<User[]>([])


    const toast = useRef(null)

    const resetForm = () => {
        setName('')
    }

    const handleClick = () => {

        setLoading(true)

        const user_ids = selectedUsers.map(item => item.id)
        addStation(name, address, user_ids  )
            .then(response => {


                (toast.current as unknown as any).show(
                    {
                        severity: 'success',
                        summary: 'Ok',
                        detail: 'Station ajoutée',

                    }
                )

                setLoading(false)


            })
            .catch(error => {


                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)


            })
    }



    return (
        <div style={formStyle}>

            <span className="p-float-label" >
                <InputText
                    value={name}
                    style={{ width: "60vw" }}
                    onChange={(event) => setName(event.target.value)}
                    name="name"
                />
                <label htmlFor="name">Nom de la station</label>
            </span>

            <span className="p-float-label" >
                <InputText
                    value={address}
                    style={{ width: "60vw" }}
                    onChange={(event) => setAddress(event.target.value)}
                    name="address"
                />
                <label htmlFor="address">Addresse</label>
            </span>

            <div style={{ width: "60vw" }}>
                <UsersGetter
                    maxSelection={MAX_SELECTION_LIMIT}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers} />
            </div>

            <Button label="Ajouter"
                loading={loading}
                icon="pi pi-plus"
                style={{ width: "60vw" }}
                onClick={() => handleClick()}
            />
            <Toast ref={toast} position="bottom-center" />
        </div>
    )

}


const formStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2em",
    flexDirection: "column" as const,
    gap: "2em",
}

export default CreateStationForm;