import DashboardCard from "./DashboardCard"
import stationImg from './../assets/station.webp'
import userImg from './../assets/user.webp'
import versementsImg from './../assets/versements.webp'
import operationsImg from './../assets/operations.webp'
import clientImg from './../assets/client.webp'
import articlesImg from './../assets/articles.webp'
import controlImg from './../assets/control.webp'


import './dashboard.css'; // Import the CSS file
import { Image } from "primereact/image"
import ImageDisplayer from "../ImageDisplayer/ImageDisplayer"

function Dashboard() {

    return (
        <div >
            <div style={tilesStyle}>
                <DashboardCard
                    route='/stations/operations'
                    description="Operations"
                    image={operationsImg}
                    id={12} />

                <DashboardCard
                    route='/users/list'
                    description="Utilisateurs"
                    image={userImg}
                    id={7} />
                <DashboardCard
                    route='/stations/list'
                    description="Stations"
                    image={stationImg}
                    id={8}
                />
                <DashboardCard
                    route='/versements'
                    description="Versements"
                    image={versementsImg}
                    id={9} />

                <DashboardCard
                    route='/clients'
                    description="Clients"
                    image={clientImg}
                    id={17} />


                <DashboardCard
                    route='/articles'
                    description="Articles"
                    image={articlesImg}
                    id={20} />

                <DashboardCard
                    route='/control'
                    description="Control"
                    image={controlImg}
                    id={25} />





            </div>
        </div>
    )
}

const tilesStyle = {

    display: 'flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2em',
    maxWidth: "90vw",
    margin: '2em auto'

}
export default Dashboard;