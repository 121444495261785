import { MultiSelect } from "primereact/multiselect";
import { User } from "../models/User";
import { useEffect, useRef, useState } from "react";
import { searchUsersByUsername } from "../utils/apiCalls/users";
import { Toast } from "primereact/toast";
import { debounce } from "lodash";

function UsersGetter(
    {
        selectedUsers,
        setSelectedUsers,
        maxSelection
    }
        :
        {

            selectedUsers: User[],
            setSelectedUsers: any,
            maxSelection: number
        }
) {


    const toast = useRef(null)

    const [userOptions, setUserOptions] = useState<User[]>([])
    const [userFilter, setUserFilter] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const fetchUsers = () => {

        setLoading(true)
        searchUsersByUsername(userFilter, { limit: 30, page: 1 })
            .then(response => {

                setLoading(false)
                setUserOptions(response.data.data)

            })
            .catch(error => {


                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)


            })
    }

    const debounceFetchUsers = debounce(fetchUsers, 300)

    useEffect(() => {

        debounceFetchUsers();

        return (() => {
            debounceFetchUsers.cancel();
        })

    }, [userFilter])


    return (
        <div style={userGetterStyle}>

            <span className="p-float-label"
                style={{ width: '100%' }}
            >
                <MultiSelect
                    style={{ width: '100%' }} loading={loading}
                    filter
                    onFilter={(event) => setUserFilter(event.filter)}
                    value={selectedUsers}
                    options={userOptions}
                    optionLabel="username"
                    onChange={(event) => setSelectedUsers(event.value)}
                    selectionLimit={maxSelection}
                />
                <label>Utilisateurs</label>
            </span>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )

}
const userGetterStyle = {
    width:"100%"
}
export default UsersGetter