import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import Client from "../models/Client";
import { getClientByCriteria } from "../utils/apiCalls/clients";
import { fillPaginationParams } from "../utils/PaginationParams";
import { Toast } from "primereact/toast";
import { setConstantValue } from "typescript";
import { debounce } from "lodash";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

function ClientsList() {

    const navigate = useNavigate();

    const [clientFilter, setStationFilter] = useState<string>('')
    const [clients, setClients] = useState<Client[]>([])
    const [first, setFirst] = useState<number>(0)
    const [rows, setRows] = useState<number>(20)
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const toast = useRef(null)

    const fetchClients = () => {
        setLoading(true)
        getClientByCriteria(clientFilter, true, fillPaginationParams(first, rows))
            .then(response => {

                setClients(response.data.data)
                setTotalRecords(response.data.meta.total)

                setLoading(false)
            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )

                setLoading(false)

            })
    }

    const debouncedFetchClient = debounce(fetchClients, 300)
    useEffect(() => {
        debouncedFetchClient();
        return (() => {
            debouncedFetchClient.cancel()
        })
    }, [clientFilter])

    const handlePageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first)
        setRows(event.rows)
    }

    useEffect(() => {
        fetchClients();
    }, [rows, first])

    return (
        <div style={containerStyle}>

            <Button label="Nouveau client" icon='pi pi-plus' onClick={() => navigate('/clients/edit')} />
            <span className="p-float-label">
                <InputText value={clientFilter}
                    onChange={(event) => setStationFilter(event.target.value)}
                />
                <label>Rechercher un client</label>
            </span>

            <DataTable
                size="small"
                loading={loading}
                value={clients}
                scrollHeight="50vh"
                tableStyle={{ width: '80vw', }}>
                <Column header="Compte" field="numero_compte" />
                <Column header="Intitulé" field="intitule" />
                <Column header="En sommeil" field="active"
                    body={(rowData) => {
                        return <i className={
                            rowData.active ? 'pi pi-times' : 'pi pi-check'
                        }></i>

                    }}
                />
                <Column header='Détails'  
                body={(rowData) => {
                    return <Button
                        onClick={() => navigate(`/clients/mvt/${rowData.numero_compte}`)}
                        className="mr-2"
                        icon='pi pi-search' />
                }}
                />
                <Column header="Modifier" body={(rowData) => {
                    return <Button
                        onClick={() => navigate(`/clients/edit/${rowData.numero_compte}`)}
                        className="mr-2"
                        icon='pi pi-pencil' />
                }} />
            </DataTable>

            <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5,20, 50]} onPageChange={handlePageChange} />

            <Toast ref={toast} position="top-center" />
        </div>
    )

}

const containerStyle = {
    maxWidth: "80em",
    margin: '2em auto',
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5em"


}

export default ClientsList