import axios from 'axios';
import { BASE_URL } from '../utils/constants';

const api = axios.create({
    baseURL: BASE_URL
})

api.interceptors.request.use(config => {
    // Retrieve the access token from React state or a state management system
    const accessToken = sessionStorage.getItem('authToken');
 
    // Add the access token to the Authorization header
    config.headers.Authorization = `Bearer ${accessToken}`;
 
    return config;
 });



 export default api;