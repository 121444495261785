import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Station } from "../../models/Station";
import StationsGetter from "../../FormComponents/StationsGetter";
import { addWorkDay, closeWorkingDay, getWorkDayByStationId } from "../../utils/apiCalls/WorkDays";
import { Toast } from "primereact/toast";
import WorkDay from "../../models/Workday";
import DayStarting from "./DayStartingComponent";
import { Image } from "primereact/image";
import interditImg from './../../assets/interdit.webp'
import { Message } from "primereact/message";
import DayOperationsPanel from "./DayOperationsPanel";
import { useNavigate } from "react-router-dom";


function Dayboard() {

    const [selectedStations, setSelectedStations] = useState<Station[]>([])
    const [operationsAllowed, setOperationsAllowed] = useState<boolean>(false)
    const [allMeasuresTaken, setAllMeasuresTaken] = useState<boolean>(false)



    return (
        <div style={containerStyle}>
            <StationsGetter disabled={true}
                maxSelection={1}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations} />

            {

                //controls for operations (entree sorties etc.. ) 

                //does not show up if !operationsAllowed or !allMeasuresTaken for the current day
                operationsAllowed && <DayOperationsPanel
                    allMeasuresTaken={allMeasuresTaken}
                    setAllMeasuresTaken={setAllMeasuresTaken}
                    selectedStation={selectedStations[0]} />


            }
           

            {
                // control buttons to open and close day
            }
            <DayStarting
                operationsAllowed={operationsAllowed}
                setOperationsAllowed={setOperationsAllowed}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations}
            />

            {
                !operationsAllowed &&
                <div style={imageContainerStyle}>

                    <Image src={interditImg} width="300" />
                    <Message severity="info" text="Ouvrez la journée pour pouvoir effectuer des opérations" />
                </div>
            }


        </div>
    )


}


const containerStyle = {
    maxWidth: "80vw",
    margin: " 2em auto",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "flex-start",
    alignItems: "center",
    height: "80vh",

}

const imageContainerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    margin: "1em 0",
    gap: "1em",
    justifyContent: 'center',
    alignItems: 'center'
}

export default Dayboard;