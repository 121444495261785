import { useEffect, useRef, useState } from "react";
import WorkDay from "../../models/Workday";
import { Station } from "../../models/Station";
import { addWorkDay, canCloseDay, closeWorkingDay, getWorkDayByStationId, openWorkingDay } from "../../utils/apiCalls/WorkDays";
import { Toast } from "primereact/toast";
import StationsGetter from "../../FormComponents/StationsGetter";
import { compareAdonisDateToJsDateAtZero } from "../../utils/utils";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { setTokenSourceMapRange } from "typescript";
import { useNavigate } from "react-router-dom";

function DayStarting(
    {
        selectedStations,
        setSelectedStations,
        operationsAllowed,  //set to false when no working day is opened
        setOperationsAllowed
    }
        :
        {
            selectedStations: Station[],
            setSelectedStations: any,
            operationsAllowed: boolean,
            setOperationsAllowed: any


        }) {


    const [lastSavedWorkday, setLastSavedWorkDay] = useState<WorkDay>()
    const [isLastDayToday, setIsLastDayToday] = useState<boolean>(false)
    const [isDayOpen, setIsDayOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [isDayClosureAllowed, setIsDayClosureAllowed] = useState<boolean>(false)
    const toast = useRef(null)

    const navigate = useNavigate();

    const getLastSavedDay = () => {

        setLoading(true)

        if (selectedStations[0])
            getWorkDayByStationId(selectedStations[0].id)
                .then(response => {

                    setLastSavedWorkDay(response.data)
                    setLoading(false)
                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                    setLoading(false)

                })
    }

    const checkCanCloseDay = () => {

        setLoading(true)
        if (selectedStations[0])
            canCloseDay(selectedStations[0].id)
                .then(response => {

                    setIsDayClosureAllowed(response.data.canCloseDay)

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                    setLoading(false)

                })
    }

    useEffect(() => {
        getLastSavedDay();
        checkCanCloseDay();
    }, [selectedStations])

    const closeDay = () => {
        setLoading(true)

        if (lastSavedWorkday && selectedStations[0])
            closeWorkingDay(selectedStations[0].id, lastSavedWorkday.id)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: `Journée du ${new Date(lastSavedWorkday.created_at).toLocaleDateString('fr')} cloturée`
                        }
                    )
                    setLoading(false)
                    getLastSavedDay();

                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                    setLoading(false)
                })
    }

    const openCurrentDay = () => {

        setLoading(true)

        if (selectedStations[0] && lastSavedWorkday)
            openWorkingDay(selectedStations[0].id, lastSavedWorkday.id)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: `Journée ${new Date(lastSavedWorkday.created_at).toLocaleDateString('fr')} ouverte`
                        }
                    )

                    getLastSavedDay();
                    setLoading(false)




                }).catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )
                    setLoading(false)


                })
    }


    const addNewWorkDay = () => {

        setLoading(true)

        if (selectedStations[0]) {
            addWorkDay(selectedStations[0].id)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: `Journée ${new Date().toLocaleDateString('fr')} ouverte`
                        }
                    )

                    getLastSavedDay();
                    setLoading(false)


                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })

        }
    }
    useEffect(() => {

        if (lastSavedWorkday) {

            const isToday = compareAdonisDateToJsDateAtZero(lastSavedWorkday.created_at, new Date())
            setIsLastDayToday(isToday)
            setIsDayOpen(!lastSavedWorkday.cloture)

        }


    }, [lastSavedWorkday])


    useEffect(() => {


        if (lastSavedWorkday) {
            if (isLastDayToday && isDayOpen) {
                setOperationsAllowed(true)
            }
            else {
                setOperationsAllowed(false)
            }
        }

    }, [isLastDayToday, isDayOpen])



    return (<div style={containerStyle}>

        <Button label="Tableau de shift" icon='pi pi-table'
        onClick={(event) =>{

            if(selectedStations[0])
            {

                navigate(`/stations/tableauShift/${selectedStations[0].id}`)

            }

        }}
        />

        {
            lastSavedWorkday && isLastDayToday && isDayOpen && isDayClosureAllowed &&
            <Button
                loading={loading}
                disabled={!isDayClosureAllowed}
                icon='pi pi-moon'
                severity="secondary"
                onClick={() => closeDay()}
                style={closeBtnStyle}>
                <h4 style={{ margin: "0" }}>
                    Clôturer la journée du
                    {(new Date(lastSavedWorkday.created_at)).toLocaleDateString('fr')}</h4>

            </Button>




        }

        {
            lastSavedWorkday && isLastDayToday && isDayOpen && !isDayClosureAllowed &&
            <div style={closeDayNotAllowedStyle}>
                <Message severity="warn" text="Effectuez une dernière mesure de cuves avant de clôturer la journée" />
                <Button
                    loading={loading}
                    disabled={!isDayClosureAllowed}
                    icon='pi pi-moon'
                    severity="secondary"
                    onClick={() => closeDay()}
                    style={closeBtnStyle}>
                    <h4 style={{ margin: "0" }}>
                        Clôturer la journée du
                        {(new Date(lastSavedWorkday.created_at)).toLocaleDateString('fr')}</h4>

                </Button>


            </div>
        }

        {
            lastSavedWorkday && isLastDayToday && !isDayOpen &&
            <Button style={openBtnStyle}
                loading={loading}
                icon='pi pi-sun'
                severity="success"
                onClick={() => openCurrentDay()}
            >
                <h4 style={{ margin: "0" }}>
                    Ouvrir la journée du
                    {(new Date(lastSavedWorkday.created_at)).toLocaleDateString('fr')}</h4></Button>
        }

        {
            lastSavedWorkday && !isLastDayToday && isDayOpen &&
            <div style={errorContainerStyle}>
                <Message severity="error" text={`La journée du ${(new Date(lastSavedWorkday.created_at)).toLocaleDateString('fr')}
                n'a pas été clôturée.`}>
                </Message>

                <Button
                    loading={loading}
                    icon='pi pi-moon'
                    severity="secondary"
                    onClick={() => closeDay()}
                    style={closeBtnStyle}>
                    <h4 style={{ margin: "0" }}>
                        Clôturer la journée du
                        {(new Date(lastSavedWorkday.created_at)).toLocaleDateString('fr')}</h4>

                </Button>
            </div>
        }
        {
            lastSavedWorkday && !isLastDayToday && !isDayOpen &&
            <Button style={openBtnStyle}
                loading={loading}
                icon='pi pi-sun'
                severity="success"
                onClick={() => addNewWorkDay()}
            >
                <h4 style={{ margin: "0" }}>
                    Ouvrir la journée du
                    {(new Date()).toLocaleDateString('fr')}</h4></Button>
        }

        {
            !lastSavedWorkday && <Button style={openBtnStyle}
                loading={loading}
                icon='pi pi-sun'
                severity="success"
                onClick={() => addNewWorkDay()}
            >
                <h4 style={{ margin: "0" }}>
                    Ouvrir la journée du
                    {(new Date()).toLocaleDateString('fr')}</h4></Button>
        }
        <Toast position="top-center" ref={toast} />

    </div>
    )




}


const containerStyle = {
    maxWidth: "80vw",
    margin: "auto",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "flex-start",

}



const openBtnStyle = {
    display: "flex",
    flexDirection: "row" as const,
    gap: '0.5em',
    fontSize: "0.8em",
    width: "fit-content"
}

const closeBtnStyle = {
    display: "flex",
    flexDirection: "row" as const,
    gap: '0.5em',
    fontSize: "0.8em",
    width: "fit-content"

}


const errorContainerStyle = {

    display: "flex",
    flexDirection: "row" as const,
    margin: "1em 0",
    gap: "1em",
    flexWrap: "wrap" as const, // allow items to wrap to the next line on smaller screens
    maxWidth: "100vw", // limit the width to the viewport width
    padding: "1em", // add some padding to make it more readable
}

const closeDayNotAllowedStyle ={
    display:"flex",
    flexDirection:"column" as const,
    gap:"1em",
    justifyContent:"center",
    alignItems:"center"
}

export default DayStarting;