import { Menubar } from "primereact/menubar";
import { useLocation, useNavigate } from "react-router-dom";

function MenuBar() {

    const location = useLocation();

    const navigate = useNavigate();

    const excludedRoutes = ['/', '/login']; // add the routes you want to exclude

    const items = [
        {
            label:"Accueil",
            icon:"pi pi-home",
            command: () =>{
                navigate('/dashboard')
            }
        },

        {
            label:"Stations",
            icon:"pi pi-car",
            items:[
                {
                    label:"Nouvelle station",
                    icon:"pi pi-plus",
                    command:() =>{
                        navigate('/stations')
                    }
                },
                {
                    label:"Liste des stations",
                    icon:"pi pi-list",
                    command:() =>{
                        navigate('/stations/list')
                    }
                },

            ]
            
        },

        {
            label: "Utilisateurs",
            items: [
                {
                    label: "Ajouter un compte",
                    icon: "pi pi-user-plus",
                    command: () => {
                        navigate('/users')
                    }
                },
                {
                    label: "Liste des utilisateurs",
                    icon: "pi pi-list",
                    command: () => {
                        navigate('/users/list')

                    }
                }

            ]
        }

    ];

    if (excludedRoutes.includes(location.pathname)) {

        return null; // don't render the navbar on these routes

    }
    return (
        <div>
            <Menubar model={items} />
        </div>
    )

}

export default MenuBar