import { useEffect, useRef, useState } from "react";
import UsersGetter from "../FormComponents/UsersGetter";
import { User } from "../models/User";
import { MAX_SELECTION_LIMIT } from "../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getStationById, updateStation } from "../utils/apiCalls/stations";
import { Toast } from "primereact/toast";
import { Station } from "../models/Station";
import { stat } from "fs";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

function SingleStationScreen() {

    const [selectedUsers, setSelectedUsers] = useState<User[]>([])
    const [station, setStation] = useState<Station | undefined>()
    const [name, setName] = useState<string>('')
    const [address, setAddress] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    const { id } = useParams();

    const toast = useRef(null)

    useEffect(() => {
        fetchStation()
    }, [id])

    const fetchStation = () => {


        if (id) {
            setLoading(true)
            getStationById(parseInt(id))
                .then(response => {
                    setLoading(false)

                    setStation(response.data)


                })
                .catch(error => {


                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                    setLoading(false)

                })
        }

    }

    useEffect(() => {
        if (station) {
            setSelectedUsers(station.authorized_users)
            setName(station.name)
            setAddress(station.address)
        }
    }, [station])


    const handleUpdateStation = () => {
        if (id) {
            const updatedStation: Station = {
                address: address,
                authorized_users: selectedUsers,
                id: parseInt(id),
                name: name,
                cuves: []
            }

            updateStation(updatedStation.id,
                updatedStation.name,
                updatedStation.address,
                selectedUsers.map(item => item.id)
            )
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: "Station modifiée"
                        }
                    )
                    fetchStation();

                    setLoading(false)

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                    setLoading(false)

                })


        }
    }


    return (
        <div style={containerStyle}>

            <span className="p-float-label" style={{ width: "100%" }}>
                <InputText value={name}
                    style={{ width: "100%" }}
                    onChange={(event) => setName(event.target.value)}
                    name="name" />
                <label htmlFor="name">Nom de la station</label>
            </span>

            <span className="p-float-label" style={{ width: "100%" }}>
                <InputText value={address}
                    style={{ width: "100%" }}
                    onChange={(event) => setAddress(event.target.value)}
                    name="name" />
                <label htmlFor="name">Adresse</label>
            </span>

            <UsersGetter maxSelection={MAX_SELECTION_LIMIT}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers} />

            <div style={controlsContainerStyle}>

                <Button label="Modifier"
                    icon='pi pi-pencil'
                    onClick={() => handleUpdateStation()} />

                <Button label="Gérer les cuves"
                    icon='pi pi-gauge'

                    onClick={() => {
                        if (station) {
                            navigate(`/operations/cuves/${station.id}`)
                        }
                    }
                    }
                />
            </div>

            <Toast ref={toast} />

        </div>
    )

}


const containerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    width: "80vw",
    margin: "3em auto",
    gap: "2.5em"
}

const controlsContainerStyle = {

    display: "flex",
    flexDirection: "row" as const,
    gap: "1em"

}
export default SingleStationScreen;