
export function apiFunctionsFactory(
    method: string,
    url: string,
    body: any | undefined = undefined,
    
  ) {
    return {
      method: method,
      url: url,
      data: body,
      withCredentials: true,
    };
  }

  export function compareAdonisDateToJsDateAtZero(date1: Date, date2: Date): boolean {
    // Create new Date objects at zero hour based on original dates
    const adonisDate = new Date(date1)
    const zeroDate1 = new Date(adonisDate.getFullYear(), adonisDate.getMonth(), adonisDate.getDate());
    const zeroDate2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  
    // Compare the timestamps of these zero-hour dates
    const response = zeroDate1.getTime() === zeroDate2.getTime();
    console.log({ response });
    return response;
  }
  

  export function setAdonisJsDateToZero(adonisJSDate: string)
  {
    const jsDate =  new Date(adonisJSDate);
    const response =  new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate());

    console.log({response})
    return response;
  }

  export function isAdonisJSDateToday(adonisJSDate: string)
  {
    const jsDate =  new Date(adonisJSDate);
    const convertedAdonisDate =  new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate());
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    return convertedAdonisDate.getTime() === today.getTime()
  }