import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import { Cuve } from "../../models/Cuve";
import { BASE_URL } from "../constants";
import { MouvementCuve } from "../../models/MouvementCuve";
import { MouvementsClient } from "../../models/MouvementsClients";
import { PaginationParams } from "../PaginationParams";
import { BasePageResponse } from "../apiResponse/BasePagedResponse";

export function getMouvementsByClient(
  numero_compte: string,
  dateDebut: Date,
  dateFin: Date,
  paginationParams: PaginationParams
): Promise<BasePageResponse<MouvementsClient>> {
  return api.post("getMouvementsByClient", {
    numero_compte: numero_compte,
    dateDebut: dateDebut,
    dateFin: dateFin,
    paginationParams: paginationParams,
  });
}


export function facturerMouvementList(mouvement_ids: number[])
{

  return api.post('facturerMouvementList', {
    mouvement_ids: mouvement_ids
  })

}

export function reglerMouvementsClientList(mouvement_ids: number[])
{

  return api.post('reglerMouvementsClientList', {
    mouvement_ids: mouvement_ids
  })

}