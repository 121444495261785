import { AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import WorkDay from "../../models/Workday";
import { CanCloseDayResponse } from "../../models/CanCloseDayResponse";

export function addWorkDay(station_id: number)
{
  return  api.post('addWorkingDay', {
        station_id: station_id
    })
}

export function getWorkDayByStationId(station_id:number) : Promise<AxiosResponse<WorkDay>>
{
  return  api.post('getWorkDayByStationId', {
    station_id: station_id
})
}

export function closeWorkingDay (station_id: number, day_id: number)
{
  return  api.post('closeWorkingDay', {
    station_id: station_id,
    day_id: day_id
})
}

export function openWorkingDay (station_id: number, day_id: number)
{
  return api.post('openWorkingDay', {
    station_id: station_id,
    day_id: day_id
  })
}


export function canCloseDay (station_id: number): Promise<AxiosResponse<CanCloseDayResponse>>
{

  return api.post('canCloseDay', {
    station_id: station_id
  })

}