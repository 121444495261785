import { Card } from "primereact/card"
import { Image } from "primereact/image"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { checkAccess } from "../utils/apiCalls/auth"
import { COMPONENT_IDS } from "../utils/constants"
import { Toast } from "primereact/toast"

function DashboardCard(

    {
        description,
        image,
        route,
        id
    }
        :
        {
            description: string,
            image: any,
            route: string,
            id: number
        }
) {


    const [canRender, setCanRender] = useState<boolean>(false)

    const toast = useRef(null)

    useEffect(() => {

        if (description && id) {
            checkAccess(id, description)
                .then(response => {

                    setCanRender(response.data)

                })
                .catch(error => {

                    setCanRender(false)

                })
        }



    }, [])

    const navigate = useNavigate();


    if (canRender) {
        return (



            <Card className="card" onClick={(event) => navigate(route)}>
                <div style={cardStyle} >
                    <h2>{description}</h2>
                    <Image src={image} width="250" />

                </div>

            </Card>
        )
    }
    else { return <></> }

}

const cardStyle = {
    display: 'flex',
    justifyContent: "center",
    flexDirection: "column" as const,
    alignItems: "center",
    height: "30vh"
}

export default DashboardCard