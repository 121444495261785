import axios, { AxiosInstance, AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import Client from "../../models/Client";
import { PaginationParams } from "../PaginationParams";
import { BasePageResponse } from "../apiResponse/BasePagedResponse";
import { Article } from "../../models/Article";

export function addArticle(intitule: string,
  valeur_prix: number
): Promise<AxiosResponse<Article>> {
  return api.post("addArticle", {
    intitule: intitule,
    valeur_prix:valeur_prix
    
  });
}

export function updateArticle(
  article: Article
): Promise<AxiosResponse<Article>> {
  return api.post("editArticle", {
    article: article,
  });
}

export function getArticleByID(
  article_id: number
): Promise<AxiosResponse<Article>> {
  return api.post("getArticleByID", {
    article_id: article_id,
  });
}


export function getArticlesByCriteria (intitule: string, paginationParams: PaginationParams):Promise<BasePageResponse<Article>> 
{
  return api.post('/getArticlesByCriteria', {
    intitule: intitule, 
    paginationParams: paginationParams
  })
}