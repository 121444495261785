import { Button } from "primereact/button"
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { Station } from "../../models/Station";
import { getStationById } from "../../utils/apiCalls/stations";
import { Cuve } from "../../models/Cuve";
import { getCuveById, getMouvementsCuveByCriteria } from "../../utils/apiCalls/cuves";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import ImageDisplayer from "../../ImageDisplayer/ImageDisplayer";
import { HistoriqueCuve } from "../../models/HistoriqueCuve";
import HistoriqueCuveTile from "./HistoriqueCuveTile";


export function HistoriqueCuveComponent() {


    const navigate = useNavigate();
    const { cuve_id } = useParams();

    const toast = useRef(null)


    const [cuve, setCuve] = useState<Cuve | undefined>()
    const [dateDebut, setDateDebut] = useState<Date>(new Date())
    const [dateFin, setDateFin] = useState<Date>(new Date())
    const [mouvementsCuve, setMouvementsCuve] = useState<HistoriqueCuve[]>([])

    const fetchCuve = () => {
        if (cuve_id)
            getCuveById(parseInt(cuve_id))
                .then(response => {

                    setCuve(response.data)

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        })
                })

    }

    const fetchMouvementsCuve = () => {
        if (cuve)
            getMouvementsCuveByCriteria(cuve.id, dateDebut, dateFin)
                .then(response => {

                    setMouvementsCuve(response.data);


                })
                .catch(error => {
                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        })

                })
    }

    useEffect(() => {
        fetchCuve()
    }, [cuve_id])

    useEffect(() => {
        if (cuve) {
            fetchMouvementsCuve();
        }
    }, [cuve])


    return (<div style={containerStyle}>
        <h2>{cuve?.reference}</h2>
        <div>

            <Button
                icon='pi pi-plus'
                label="Ajouter un mouvement"
                onClick={() => {
                    navigate(`/stations/operations/cuves/add/${cuve_id}`)
                }} />
        </div>

        <div style={formStyle}>
            <span className="p-float-label">
                <Calendar value={dateDebut} locale="fr"
                    onChange={(event) => setDateDebut(event.value ? event.value : new Date())} />
                <label>De: </label>
            </span>

            <span className="p-float-label">
                <Calendar value={dateFin} locale="fr"
                    onChange={(event) => setDateFin(event.value ? event.value : new Date())} />
                <label>Fin: </label>
            </span>

            <Button icon='pi pi-sync' onClick={() => fetchMouvementsCuve()} />
        </div>

        {
            mouvementsCuve.map((item, index) => {
                return <div style={{ margin: "1em 0" }}>
                    <HistoriqueCuveTile
                        displayImage={true}
                        mouvementCuve={item}
                        key={index} />
                </div>
            })

        }


        <Toast ref={toast} position="bottom-center" />
    </div>)
}


const containerStyle = {
    maxWidth: "80vw",
    margin: "auto"
}

const formStyle = {

    margin: "2em 0",
    display: "flex",
    flexWrap: "wrap" as const, // add this line
    gap: "2em"

}

export default HistoriqueCuveComponent