import { Button } from "primereact/button"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Article } from "../models/Article";
import { getArticlesByCriteria } from "../utils/apiCalls/articles";
import { fillPaginationParams } from "../utils/PaginationParams";
import { debounce } from "lodash";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

function ArticlesList() {

    const navigate = useNavigate();

    const [articleFilter, setArticleFilter] = useState<string>('')
    const [articles, setArticles] = useState<Article[]>([])
    const [first, setFirst] = useState<number>(0)
    const [rows, setRows] = useState<number>(20)
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const toast = useRef(null)

    const fetchArticles = () => {
        setLoading(true)
        getArticlesByCriteria(articleFilter, fillPaginationParams(first, rows))
            .then(response => {

                setArticles(response.data.data)
                setTotalRecords(response.data.meta.total)
                setLoading(false)
            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )

                setLoading(false)

            })
    }

    const debouncedFetchArticles = debounce(fetchArticles, 300)
    useEffect(() => {
        debouncedFetchArticles();
        return (() => {
            debouncedFetchArticles.cancel()
        })
    }, [articleFilter])


    const handlePageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first)
        setRows(event.rows)
    }

    useEffect(() => {
        fetchArticles();
    }, [rows, first])



    // return (
    //     <div>
    //         <Button label="Nouvel article" icon='pi pi-plus'
    //             onClick={() => {
    //                 navigate('/articles/edit')
    //             }}
    //         />
    //     </div>
    // )

    return (
        <div style={containerStyle}>

            <Button label="Nouvel article" icon='pi pi-plus' onClick={() => navigate('/articles/edit')} />
            <span className="p-float-label">
                <InputText value={articleFilter}
                    onChange={(event) => setArticleFilter(event.target.value)}
                />
                <label>Rechercher un article</label>
            </span>

            <DataTable
                size="small"
                loading={loading}
                value={articles}
                scrollHeight="50vh"
                tableStyle={{ width: '80vw', }}>
                <Column header="Intitulé" field="intitule" />
                <Column header="Dernier prix" body={(rowData) => {

                    return rowData.price ? rowData.price[0].valeur : '';

                }} />
                <Column header="Modifier" body={(rowData) => {
                    return <Button
                        onClick={() => navigate(`/articles/edit/${rowData.id}`)}
                        className="mr-2"
                        icon='pi pi-pencil' />
                }} />
            </DataTable>

            <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5, 20, 50]} onPageChange={handlePageChange} />

            <Toast ref={toast} position="top-center" />
        </div>
    )

}


const containerStyle = {
    maxWidth: "80em",
    margin: '2em auto',
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5em"


}

export default ArticlesList