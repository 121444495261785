import { useEffect, useRef, useState } from "react";
import { Station } from "../models/Station";
import { DataTable } from 'primereact/datatable';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { searchStations } from "../utils/apiCalls/stations";
import { fillPaginationParams } from "../utils/PaginationParams";
import { debounce } from "lodash";
function ListeStations() {

    const [stations, setStations] = useState<Station[]>([])
    const [stationsFilter, setStationsFilter] = useState<string>('')
    const [first, setFirst] = useState<number>(0)
    const [rows, setRows] = useState<number>(20)
    const [totalRecords, setTotalRecords] = useState<number>(0)

    const [loading, setLoading] = useState<boolean>(false)

    const toast = useRef(null)


    const fetchStations = () => {

        setLoading(true)


        searchStations(stationsFilter, fillPaginationParams(first, rows))
            .then(response => {
                setLoading(false)

                setStations(response.data.data)
                setTotalRecords(response.data.meta.total)


            })
            .catch(error => {

                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message
                    }
                )
                setLoading(false)

            })

    }

    const debounceFetchStations = debounce(fetchStations, 300);

    useEffect(() => {

        debounceFetchStations();

        return (() => {
            debounceFetchStations.cancel();
        })

    }, [stationsFilter])

    useEffect(() => {
        fetchStations();
    }, [first, rows])

    const handlePageChange = (event: PaginatorPageChangeEvent) => {

        setFirst(event.first)
        setRows(event.rows)


    }

    const navigate = useNavigate();


    return (
        <div style={containerStyle}>
            <div style={headerStyle} >
                <Button icon='pi pi-plus'
                    label="Ajouter une station"
                    onClick={() => navigate('/stations')}
                />
                <span className='p-float-label'>
                    <InputText
                        value={stationsFilter}
                        onChange={(event) => setStationsFilter(event.target.value)}
                    />
                    <label>Nom de la station</label>
                </span>
            </div>

            <DataTable
                loading={loading}
                value={stations}
                scrollHeight="50vh"
                tableStyle={{ width: '80vw', }}>
                <Column header="Nom" field="name" />
                <Column header="Addresse" field="address" />
                <Column header="Modifier" body={(rowData) => {
                    return <Button
                        onClick={() => navigate(`/stations/edit/${rowData.id}`)}
                        icon='pi pi-pencil' />
                }} />
            </DataTable>
            <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[20, 50]} onPageChange={handlePageChange} />

            <Toast ref={toast} />
        </div>
    )


}


const containerStyle = {
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    margin: "2em",
    flexDirection: "column" as const,
    gap: "2em"
}

const headerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5em",
}

export default ListeStations;