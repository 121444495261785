import { Button } from "primereact/button"
import { Station } from "../../models/Station"
import { useNavigate, useParams } from "react-router-dom"
import { getCuvesByStationID } from "../../utils/apiCalls/cuves";
import { stat } from "fs";
import { useEffect, useRef, useState } from "react";
import { Cuve } from "../../models/Cuve";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function ListCuves() {

    const { station_id } = useParams();
    const navigate = useNavigate()

    const [cuves, setCuves] = useState<Cuve[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(null)



    const fetchCuves = () => {

        if (station_id)
            getCuvesByStationID(parseInt(station_id))
                .then(response => {

                    setCuves(response.data)

                })
                .catch(error => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message
                        }
                    )

                })
    }

    useEffect(() => {

        if (station_id) {
            fetchCuves();
        }

    }, [station_id])


    return (
        <div style={containerStyle}>

            <Button icon='pi pi-plus'
                label="Nouvelle cuve"
                onClick={() => { navigate('/operations/cuve') }}
            />

            <DataTable value={cuves} size="small" style={{ width: "100%" }}>
                <Column header='Référence'
                    field="reference"
                />


                <Column header='Article'
                    field="article.intitule"
                />


                <Column header='Capacité'
                    field="capacite"
                />


                <Column header='Stock'
                    field="stock"
                />

                <Column header='Modifier'
                    body={
                        (rowData) => {
                            return <Button icon='pi pi-pencil'
                                onClick={() => navigate(`/operations/cuve/${rowData.id}`)}
                            />
                        }
                    }
                />
            </DataTable>

            <Toast ref={toast} position="top-center" />

        </div>
    )

}

const containerStyle = {
    maxWidth: "80vw",
    margin: '2em auto',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as const,
    gap: "2em"
}

export default ListCuves