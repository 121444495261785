import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"

import { useEffect, useRef, useState } from "react"
import { addUser } from "../utils/apiCalls/users"
import { Toast } from 'primereact/toast'
import { Role } from "../models/Role"
import { getRoles } from "../utils/apiCalls/auth"

function NewUserForm() {


    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const [roleOptions, setRoleOptions] = useState<Role[]>([])
    const [selectedRole, setSelectedRole] = useState<Role | undefined>()

    const toast = useRef(null)

    const handleAddUser = () => {
        if (selectedRole)
            addUser(username, password, selectedRole?.id)
                .then(response => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'success',
                            summary: 'Ok',
                            detail: 'Utilisateur ajouté',
                        }
                    )

                    resetForm();

                })
                .catch((error) => {

                    (toast.current as unknown as any).show(
                        {
                            severity: 'error',
                            summary: 'Erreur',
                            detail: error.message,
                        }
                    )


                })

    }


    const fetchRoles = () => {
        getRoles()
            .then(response => {

                setRoleOptions(response.data)

            })
            .catch(error => {


                (toast.current as unknown as any).show(
                    {
                        severity: 'error',
                        summary: 'Erreur',
                        detail: error.message,
                    }
                )

            })
    }

    const resetForm = () => {
        setUsername('')
        setPassword('')

    }

    useEffect(() => {
        fetchRoles();
    }, [])

    return (<div style={formStyle}>

        <div style={innerStyle}>
            <h2>Nouvel utilisateur</h2>
            <span className="p-float-label">


                <InputText
                    placeholder="Nom d'utilisateur"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)} />

                <label>Nom d'utilisateur</label>

            </span>

            <span className="p-float-label" style={{ width: "100%" }}
            >
                <Dropdown options={roleOptions}
                    optionLabel="role"
                    value={selectedRole}
                    onChange={(event) => setSelectedRole(event.value)}
                    style={{ width: "100%" }}
                    width={'100%'}
                />

                <label>Role</label>


            </span>

            <span className="p-float-label">


                <InputText
                    type="password"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)} />

                <label>Mot de passe</label>

            </span>

            <Button
                label="Ajouter"
                icon='pi pi-user'
                style={{ width: "100%" }}
                loading={loading}
                onClick={() => handleAddUser()}
            />

        </div>

        <Toast ref={toast} position="bottom-center" />

    </div>)


}
const formStyle = {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",

}

const innerStyle = {
    border: "1px solid lightgray",
    borderRadius: "0.3em",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as const,
    gap: "2em",
    padding: "2em"


}
export default NewUserForm