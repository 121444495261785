
import { Component } from "../models/Component";


export const BASE_URL = process.env.REACT_APP_BASE_URL;


export const MAX_SELECTION_LIMIT = 10000;

export const COMPONENT_IDS: Component[] = [
  {
    component: "Dashboard",
    description: "",
    id: 1,
  },
  {
    component: "SearchUser",
    description: "",
    id: 2,
  },
  {
    component: "NewUserForm ",
    description: "",
    id: 3,
  },
  {
    component:"CreateStationForm",
    description:"",
    id:4
  },
  {
    component:'ListeStations',
    description:"",
    id:5
  },
  {
    component:"SingleStationScreen",
    description:"",
    id:6
  },
  {
    component:"DashboardCard",
    description:"Utilisateurs",
    id:7
  },
  {
    component:"DashboardCard",
    description:"Stations",
    id:8
  },
  {
    component:"DashboardCard  ",
    description:"Versements",
    id:9
  }
];


export const TYPES_MOUVEMENT_STOCK = {
  'ENTREE': 1,
  'SORTIE':2
}