import axios, { AxiosInstance, AxiosResponse } from "axios";
import api from "../../configAxios/AxiosHttpWrapper";
import { Cuve } from "../../models/Cuve";
import { BASE_URL } from "../constants";

export function addMouvementStock(
  type_id: number,
  quantite: number,
  cuve_id: number,
  article_id: number,
  image: Blob | undefined
) {
  const accessToken = sessionStorage.getItem("authToken");

  const formData = new FormData();
  formData.append("type_id", type_id.toString());
  formData.append("quantite", quantite.toString());
  formData.append("cuve_id", cuve_id.toString());
  formData.append("article_id", article_id.toString());
  if (image) {
    formData.append("image", image, `image.jpeg`); // Set filename and contentType
  }

  return axios.post(BASE_URL + "addMouvementStock", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
